import {field} from '../../../core/models/fields/Field';
import AbstractProductOption from '../../svgcustom/models/AbstractProductOption';
import {APIResource} from '../../../core/models/APIResource';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {ConfiguredShutterstockProduct} from './ConfiguredShutterstockProduct';

export class ShutterstockProductOption extends AbstractProductOption {
    public static override uri: string = '/shutterstock/api/v1/shutterstock-product-option/:id/';
    static override objects: APIResource<ShutterstockProductOption>;

    @field(ForeignKeyURIField, {
        model: 'ConfiguredShutterstockProduct',
        readOnly: true
    })
    configured: TForeignKeyURI<ConfiguredShutterstockProduct>;

    get custom() {
        return this.configured;
    }
    set custom(v) {
        this.configured = v;
    }
}