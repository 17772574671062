import {field} from '../../../../core/models/fields/Field';
import {FloatField} from '../../../../core/models/fields/FloatField';
import {Model} from '../../../../core/models/Model';
import {RotationField} from '../../../../core/models/fields/RotationField';
import {ForeignKeyField} from '../../../../core/models/fields/ForeignKeyField';
import {TemplateSide} from '../TemplateSide';

export class ElementAbstract extends Model {
    @field(FloatField, {
        toFixed: 3
    })
    x: number;

    @field(FloatField, {
        toFixed: 3
    })
    y: number;

    @field(FloatField, {
        toFixed: 3
    })
    z: number;

    @field(FloatField, {
        toFixed: 3
    })
    width: number;

    @field(FloatField, {
        toFixed: 3
    })
    height: number;

    @field(RotationField)
    rotation: number;

    @field()
    maintain_proportion: boolean;

    // Template Element fields
    @field()
    can_remove: boolean;

    @field()
    dpi: number;

    @field(ForeignKeyField, {
        model: 'TemplateSide'
    })
    side: TemplateSide;

    // We cannot delete elements prior to the customer saving their product
    // Queue it to be deleted when the product is saved.
    toDelete: boolean;

    @field()
    dont_clip: boolean;

    @field()
    x_scale_inverted: boolean;

    @field()
    y_scale_inverted: boolean;

    @field()
    offset_width: number;

    @field()
    allow_clipping_when_locked: boolean;

    remove() {
        this.toDelete = true;
        this.trigger('remove', this);
    }

    // The root element that this one is based off of. Example: CustomClipart -> TemplateClipart
    get root() {
        return null;
    }

    get forced_top_layer(): boolean {
        if (this.root) {
            return !!this.root.top_layer;
        }

        return false;
    }

    // Copy all data from an element
    public copy(from: ElementAbstract) {
        this.x = from.x;
        this.y = from.y;
        this.z = from.z;
        this.width = from.width;
        this.height = from.height;
        this.rotation = from.rotation;
        this.maintain_proportion = from.maintain_proportion;
        this.can_remove = from.can_remove;
        this.toDelete = from.toDelete;
        this.side = from.side;
    }
}
