import {field} from '../../../core/models/fields/Field';
import {
    ForeignKeyURIField,
    TForeignKeyURI
} from '../../../core/models/fields/ForeignKeyURIField';
import {ToManyURIField} from '../../../core/models/fields/ToManyURIField';
import {Model} from '../../../core/models/Model';
import {Category} from './Category';
import {APIResource} from '../../../core/models/APIResource';

export class CategoryProduct extends Model {
    static override uri: string = '/products/api/v1/category-product/:id/';
    static override objects: APIResource<CategoryProduct>;

    @field()
    name: string;

    @field()
    sku: string;

    @field(ForeignKeyURIField, {
        model: 'Category'
    })
    category: TForeignKeyURI<Category>;

    @field(ToManyURIField, {
        model: 'Category'
    })
    categories: TForeignKeyURI<Category>[];

    @field()
    review_count: number;

    @field()
    review_average: number;

    @field()
    url: string;

    @field()
    image_url: string;

    @field()
    image_webp_url: string;

    @field()
    thumbnail_url: string;

    @field()
    thumbnail_webp_url: string;

    @field()
    object_id: number;

    @field()
    type: string;

    @field()
    default_price: number;

    @field()
    hidden_product: boolean;

    @field()
    order: string;
}