import {Model, TModelDefinitionList} from '../../../core/models/Model';
import {RetailPackage} from './RetailPackage'
import {PackageSize} from './PackageSize';
import ConfiguredRetailPackage from './ConfiguredRetailPackage';

export default function registerModels(): void {
    const models: TModelDefinitionList = {
        'RetailPackage': RetailPackage,
        'PackageSize': PackageSize,
        'ConfiguredRetailPackage': ConfiguredRetailPackage
    };

    Model.registerModels(models);
}
