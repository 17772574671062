import {APIResource} from '../../../../../common/ts/core/models/APIResource';
import {Model} from '../../../../../common/ts/core/models/Model';
import {field} from '../../../../../common/ts/core/models/fields/Field';

export class Tag extends Model {
    static override uri: string = '/custom/api/v1/tag/:id/';
    static override objects: APIResource<Tag>;

    @field()
    name: string;

    @field()
    slug: string;
}