import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {Color} from './Color';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {APIResource} from '../../../core/models/APIResource';
import {ColorCollection} from '../collections/ColorCollection';


export class ColorMap extends Model {
    static override uri: string = '/custom/api/v1/color-map/:id/';
    static override objects: APIResource<ColorMap>;

    @field(ForeignKeyField, {
        model:'Color'
    })
    key: Color;

    @field(ToManyField, {
        model: 'Color'
    })
    values: ColorCollection;
}