import AbstractProductOption from './AbstractProductOption';
import {APIResource} from '../../../core/models/APIResource';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {ConfiguredClipartProduct} from './ConfiguredClipartProduct';

export class ConfiguredClipartOption extends AbstractProductOption {
    static override uri: string = '/custom/api/v1/configured-clipart-option/:id/';
    static override objects: APIResource<ConfiguredClipartOption>;

    @field(ForeignKeyURIField, {
        model: 'ConfiguredClipartProduct',
        readOnly: true
    })
    configured: TForeignKeyURI<ConfiguredClipartProduct>;

    get custom() {
        return this.configured;
    }
    set custom(v) {
        this.configured = v;
    }
}