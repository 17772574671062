import {Collection} from '../../../core/models/Collection';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {
    ForeignKeyURIField, TForeignKeyURI
} from '../../../core/models/fields/ForeignKeyURIField';
import {PositiveIntegerField} from '../../../core/models/fields/PositiveNumberField';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {Price} from '../../../core/utils/Price';
import {ConfiguredProductAbstract, QuantityType} from './abstract/ConfiguredProductAbstract';
import {Color} from './Color';
import {CustomOption} from './CustomOption';
import {TemplateShape} from './TemplateShape';
import {CustomLamination, DynamicBackgroundShape, ProofApproval, ToolTypeEnum} from './enums';
import {IConfiguredProduct} from './IConfiguredProduct';
import {Template} from './Template';
import {TemplateOption, TemplateOptionChargeOption} from './TemplateOption';
import {TemplateOptionSet} from './TemplateOptionSet';
import {TemplateSize} from './TemplateSize';
import {UserFile} from './UserFile';
import {CustomMarketplace} from './CustomMarketplace';
import {ColorField} from '../../../core/models/fields/ColorField';
import {CustomClipMask} from './CustomClipMask';
import NamedRouteService from '../../../core/services/NamedRouteService';
import {Services} from '../../../core/services/Services';
import {CustomQRCode} from './CustomQRCode';
import {CustomUserFile} from './CustomUserFile';
import {CustomText} from './CustomText';
import {CustomClipart} from './CustomClipart';
import MessageList from '../../../core/utils/MessageList';
import {APIResource} from '../../../core/models/APIResource';
import {VariableDataElementAbstract} from './abstract/VariableDataElementAbstract';
import {VariableDataTextElementAbstract} from './abstract/VariableDataTextElementAbstract';
import VariableDataObjectElementAbstract from './abstract/VariableDataObjectElementAbstract';
import {TemplateSide} from './TemplateSide';
import {ToManyURIField} from '../../../core/models/fields/ToManyURIField';
import {CustomShape} from './CustomShape';

export class Custom extends ConfiguredProductAbstract implements IConfiguredProduct {
    public static override uri: string = '/custom/api/v1/custom/:id/';
    static override objects: APIResource<Custom>;
    public override productClass: string = 'custom';

    @field(ForeignKeyField, {
        model: 'Template',
        readOnly: true
    })
    template: Template;

    @field(ToManyField, {
        model: 'CustomClipart',
        full: true
    })
    clipart_elements: Collection<CustomClipart>;

    @field(ToManyField, {
        model: 'CustomText',
        full: true
    })
    text_elements: Collection<CustomText>;

    @field(ToManyField, {
        model: 'CustomUserFile',
        full: true
    })
    upload_elements: Collection<CustomUserFile>;

    @field(ToManyField, {
        model: 'CustomQRCode',
        full: true
    })
    qrcode_elements: Collection<CustomQRCode>;

    @field(ToManyField, {
        model: 'CustomMarketplace',
        full: true
    })
    marketplace_elements: Collection<CustomMarketplace>;

    @field(ToManyField, {
        model: 'CustomClipMask',
        full: true
    })
    clip_mask_elements: Collection<CustomClipMask>;

    @field(ForeignKeyField, {
        model: 'TemplateSize',
        readOnly: true
    })
    size: TemplateSize;

    @field()
    tool_type: ToolTypeEnum;

    @field()
    option_vectorize: boolean;

    @field()
    option_vectorize_price: number;

    @field()
    option_border: boolean;

    @field(ToManyField, {
        model: 'CustomOption'
    })
    override options: Collection<CustomOption>;

    @field()
    instructions: string;

    @field(ForeignKeyURIField, {
        model: 'UserFile',
        readOnly: true
    })
    user_file: TForeignKeyURI<UserFile>;

    @field()
    canvas_preview: string;

    @field()
    canvas_preview_small: string;

    @field()
    source_file: string;

    @field()
    is_cropped: boolean;

    @field()
    crop_height: number;

    @field()
    crop_width: number;

    @field()
    approve_skew: boolean;

    @field()
    is_changed: boolean;

    @field()
    dpi: number;

    @field()
    size_change: boolean;

    @field()
    clip_radius: any;

    @field(ForeignKeyField, {
        model: 'Color',
        readOnly: true
    })
    offset_path_fill_color: Color;

    @field()
    offset_path_custom_fill_color: string;

    get clipRadius(){
        return Number(this.clip_radius) || 10;
    }

    set clipRadius(r){
        this.clip_radius = r;
    }

    @field()
    proof_approval: number;

    @field()
    configure: boolean;

    @field()
    finalized: boolean;

    // Only available if staff
    @field()
    user_id: number;

    @field(ToManyURIField, {
        model: 'CustomShape'
    })
    custom_shapes: Collection<CustomShape>;

    public imageDoesNotFit: boolean;

    /*
        defined_quantity getter and setter override (both must be set)
        Used to determine if the quantity is defined by some other means
     */
    get _defined_quantity(){
        const element = this.quantity_model;
        if (element && element.definesQuantity) {
            return true;
        }

        if (this.template && this.template.limited_quantity) {
            return true;
        }

        return false;
    }
    set _defined_quantity(value){
        this['__defined_quantity'].value = value;
    }

    get _variable_data() {
        const element = this.quantity_model;
        return element && element.definesQuantity;
    }

    set _variable_data(v) {
        return
    }

    /*
        quantity getter and setter override (both must be set)
     */
    get _quantity() {
        const element = this.quantity_model;
        if (element && element.definesQuantity) {
            return element.range;
        }

        return this['__quantity'].value;
    }
    set _quantity(value) {
        const element = this.quantity_model;
        if (element && element.definesQuantity && element.range) {
            this['__quantity'].value = element.range;
        }
        else {
            this['__quantity'].value = value;
        }

        this['__quantity'].clean();
    }

    override get quantity_type(): QuantityType {
        const element = this.quantity_model;
        if (element && element.quantity_type !== null) {
            return element.quantity_type;
        }

        return QuantityType.SINGLE_VALUE;
    }

    get quantity_model(): VariableDataElementAbstract {
        let elements: VariableDataElementAbstract[] = [];
        if (this.text_elements && this.text_elements.length > 0) {
            elements = this.text_elements;
        }
        if (this.qrcode_elements && this.qrcode_elements.length > 0) {
            elements = [...elements, ...this.qrcode_elements];
        }

        for (const element of elements){
            if (element && element.definesQuantity && element.quantity_type !== null) {
                return element;
            }
        }

        return null;
    }

    get text_quantity_model(): VariableDataTextElementAbstract {
        if (this.text_elements && this.text_elements.length > 0) {
            return this.text_elements.find(element => element && element.definesQuantity && element.quantity_type !== null);
        }

        return null;
    }

    get qr_quantity_model(): VariableDataObjectElementAbstract {
        if (this.qrcode_elements && this.qrcode_elements.length > 0) {
            return this.qrcode_elements.find(element => element && element.definesQuantity && element.quantity_type !== null);
        }

        return null;
    }

    get optionVectorizePrice(){
        if (this.option_vectorize_price){
            return this.option_vectorize_price;
        }

        if(this.template && this.template.vectorize_price_override) {
            return Number(this.template.vectorize_price_override)
        }

        if (this.template) {
            return Number(this.template.default_vectorization_price);
        }

        // Idk what do here since we need to have all currencies localized
        return 0;
    }

    public vectorizationOptions = [
        {
            label: 'Fix My Graphic',
            id: true
        },
        {
            label: 'I Approve My Low-Resolution Graphic To Be Printed',
            id: false
        }
    ];

    public customToolTypeOptions = [
        {
            label: 'Advanced Editor',
            id: ToolTypeEnum.ADVANCED
        },
        {
            label: 'Upload',
            id: ToolTypeEnum.UPLOAD
        }
    ];

    public proofApprovalOptions = [
        {
            label: 'I approve my design and have verified the spelling, colors, and layout. I understand my artwork will not be stretched. If the product size does not match my design, it will be kept proportionate and sized to the largest dimension.',
            id: ProofApproval.AS_IS
        },
        {
            label: 'My design will need the changes or corrections listed below. I understand that these changes may incur an additional graphic design fee.',
            id: ProofApproval.CHANGES
        }
    ];

    override set product(template: Template) {
        this.template = template;
    }

    override get product(): Template {
        return this.template;
    }

    get userFile(): string {
        if (this.user_file && (this.user_file as UserFile).raster) {
            return (this.user_file as UserFile).raster;
        } else {
            return;
        }
    }

    get previewURL(): string {
        if (this.tool_type === ToolTypeEnum.UPLOAD && !this.is_cropped) {
            return this.userFile;
        }
        else if (this.isSVG) {
            return this.canvasPreview;
        }

        return;
    }

    get previewURLSmall(): string {
        if (this.tool_type === ToolTypeEnum.UPLOAD && !this.is_cropped) {
            return this.userFile;
        }
        else if (this.isSVG || this.is_cropped) {
            return this.canvasPreviewSmall;
        }

        return;
    }

    getSetupURL(): string {
        if (this.template == null)
            return '';
        return Services.get<NamedRouteService>('$NamedRouteService').reverse('svgcustom:setup-edit', {
            categoryPath: this.template.category ? this.template.category.path : 'none',
            productSlug: this.template.slug,
            configuredId: this.id
        });
    }

    getUploadURL(): string {
        if (this.template == null)
            return '';
        return Services.get<NamedRouteService>('$NamedRouteService').reverse('svgcustom:upload', {
            categoryPath: this.template.category.path,
            productSlug: this.template.slug,
            configuredId: this.id
        });
    }

    getFinalizeURL(): string {
        if (this.template == null)
            return '';

        return Services.get<NamedRouteService>('$NamedRouteService').reverse('svgcustom:finalize', {
            categoryPath: this.template.category.path,
            productSlug: this.template.slug,
            configuredId: this.id
        });
    }

    getEditURL(): string {
        let urlName = '';
        switch (this.tool_type) {
            case ToolTypeEnum.ADVANCED:
                urlName = 'personalize-edit';
                break;
            case ToolTypeEnum.EASY:
                urlName = 'easy-edit';
                break;
            case ToolTypeEnum.UPLOAD:
                urlName = 'finalize';
                break;
            case ToolTypeEnum.LETTERING:
                urlName = 'lettering-edit';
                break;
            case ToolTypeEnum.PREVIEW:
                urlName = 'preview-edit';
                break;
        }
        return Services.get<NamedRouteService>('$NamedRouteService').reverse('svgcustom:' + urlName, {
            categoryPath: this.template.category.path,
            productSlug: this.template.slug,
            configuredId: this.id
        });
    }

    getAdminEdit(): string {
        // Routes aren't setup in the dashboard due to it being in a different app so generate a fake url that will still load it
        return `/products/admin-edit/custom/${this.id}/`;
    }

    override getNextURL(): string {
        return this.getEditURL();
    }

    /**
     * Tool type checks for templates
     */
    get isStandard(): boolean {
        // Our 3 standard/basic tools
        return [
                ToolTypeEnum.ADVANCED,
                ToolTypeEnum.EASY,
                ToolTypeEnum.UPLOAD
            ].indexOf(this.tool_type) > -1;
    }

    get isSVG(): boolean {
        return [
                ToolTypeEnum.ADVANCED,
                ToolTypeEnum.EASY,
                ToolTypeEnum.LETTERING
            ].indexOf(this.tool_type) > -1;
    }

    get isUpload(): boolean {
        return [
                ToolTypeEnum.UPLOAD
            ].indexOf(this.tool_type) > -1;
    }

    get isAdvanced(): boolean {
        return this.tool_type == ToolTypeEnum.ADVANCED;
    }

    /**
     * Background shape label - This is strange here, should probably find
     * a better way to display the label of shapes.
     * @returns {string}
     */
    get label(): string {
        return 'Background';
    }

    override validate(): MessageList {
        super.validate();

        // Minimum quantity validation
        const minimum_quantity = this.template && this.template.minimum_quantity ? this.template.minimum_quantity : 1;
        if (this.quantity < minimum_quantity){
            this._errors.add('quantity', 'The minimum quantity for this product is ' + minimum_quantity + '.');
        }
        else if (this.quantity == null || isNaN(this.quantity)){
            this._errors.add('quantity', 'Quantity must be a whole number.');
        }
        this.trigger('validate', this._errors);

        return this._errors;
    }

    get canvasPreview(): string {
        if (this.tool_type === ToolTypeEnum.UPLOAD && !this.is_cropped) {
            return this.userFile;
        }
        return this.canvas_preview;
    }

    get canvasPreviewSmall(): string {
        if (this.tool_type === ToolTypeEnum.UPLOAD && !this.is_cropped) {
            return this.userFile;
        }
        if (this.canvas_preview_small != null && this.canvas_preview_small !== '') {
            return this.canvas_preview_small;
        }
        return this.canvasPreview;
    }

    public override calcOptionsPrice(price: number, show_retail?: boolean): number {
        let subTotal : Price = new Price(super.calcOptionsPrice(price, show_retail));

        // Vectorized price
        if (this.option_vectorize) {
            subTotal = subTotal.add(this.optionVectorizePrice);
        }

        // Laminate price if Matte
        if (this.option_laminate === 1) {
           subTotal = subTotal.add(0);
        }

        // Flexcut price
        if (this.option_flex_cut) {
            subTotal = subTotal.add(0);
        }

        return subTotal.value;
    }

    removeDisabledProductOptions() {
        // Remove all disabled product options before saving
        let to_remove = [];
        for (const option of this.options) {
            if (option.isDisabled(this.material)) {
                to_remove.push(option);
            }
        }

        for (const option of to_remove) {
            this.options.splice(this.options.indexOf(option), 1);
        }
    }
}
