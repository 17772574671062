import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {APIResource} from '../../../core/models/APIResource';

export class TemplateSide extends Model {
    public static override uri: string = '/custom/api/v1/template-side/:id/';
    static override objects: APIResource<TemplateSide>;

    @field()
    name: string;

    @field()
    order: any;

    @field()
    disable_offset_path: boolean;
}
