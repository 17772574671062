import {ConfiguredProductAbstract} from '../../svgcustom/models/abstract/ConfiguredProductAbstract';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {MarketplaceProduct} from './MarketplaceProduct';
import {IProduct} from '../../svgcustom/models/IProduct';
import {APIResource} from '../../../core/models/APIResource';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {Collection} from '../../../core/models/Collection';
import {ConfiguredMarketplaceOption} from './ConfiguredMarketplaceOption';
import AbstractProductOption from '../../svgcustom/models/AbstractProductOption';
import {CustomLamination} from '../../svgcustom/models/enums';

export class ConfiguredMarketplaceProduct extends ConfiguredProductAbstract {
    public static override uri: string = '/marketplace/api/v1/configured-product/:id/';
    static override objects: APIResource<ConfiguredMarketplaceProduct>;
    public override productClass: string = 'marketplaceproduct';

    @field(ForeignKeyURIField, {
        model: 'MarketplaceProduct'
    })
    public marketplace_product: TForeignKeyURI<MarketplaceProduct>;

    public override set product(product: IProduct) {
        this.marketplace_product = product as TForeignKeyURI<MarketplaceProduct>;
    }

    public override get product(): IProduct {
        return this.marketplace_product as IProduct;
    }

    @field(ToManyField, {
        model: 'ConfiguredMarketplaceOption'
    })
    override options: Collection<ConfiguredMarketplaceOption>;
}
