import {Field, IFieldConfig} from './Field';

export class ColorField extends Field {

    correct() {
        /*
        if (this.value !== null && this.value !== undefined) {
            let c = new paper.Color(this.value);

            let r = (c.components[0] * 255).toString(16);
            let g = (c.components[1] * 255).toString(16);
            let b = (c.components[2] * 255).toString(16);

            // Enforce a 0 before numbers 9 or less (00 instead of 0)
            r = r.length == 1 ? `0${r}` : r;
            g = g.length == 1 ? `0${g}` : g;
            b = b.length == 1 ? `0${b}` : b;

            this.value = `#${r}${g}${b}`
        }
         */
    }

    public override validate(): string[] {
        this.correct();
        return super.validate();
    }

}
