import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {APIResource} from '../../../core/models/APIResource';
import {Services} from '../../../core/services/Services';
import {Http} from '../../../core/services/Http';

export class MarketplaceArtwork extends Model {
    public static override uri: string = '/marketplace/api/v1/artwork/:id/';
    static override objects: APIResource<MarketplaceArtwork>;

    @field()
    artist: any;

    @field()
    name: string;

    @field()
    slug: string;

    @field()
    image: string;

    @field()
    is_vector: boolean;

    @field()
    public_raster_url: string;

    @field()
    public_raster_remove_bg: string;

    get isValid(): boolean {
        return !!this.image;
    }

    async removeBackground() {
        await Services.get<Http>('$http').request({
            url: '/marketplace/api/v1/remove-background/',
            method: 'POST',
            data: {
                id: this.id
            }
        });

        await this.reload();
    }
}
