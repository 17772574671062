import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ForeignKeyURIField} from '../../../core/models/fields/ForeignKeyURIField';
import {ProductAbstract} from './abstract/ProductAbstract';
import {Clipart} from './Clipart';
import {ClipartProductCustomizationGroup} from './ClipartProductCustomizationGroup';
import {ToManyField} from "../../../core/models/fields/ToManyField";
import {StockProductSize} from './StockProductSize';
import {Collection} from '../../../core/models/Collection';
import {Services} from '../../../core/services/Services';
import NamedRouteService from '../../../core/services/NamedRouteService';
import {APIResource} from '../../../core/models/APIResource';
import {TemplateOptionSet} from './TemplateOptionSet';

export class ClipartProduct extends ProductAbstract {
    static override uri: string = '/custom/api/v1/clipart-product/:id/';
    static override objects: APIResource<ClipartProduct>;

    @field(ForeignKeyField, {
        model: 'Clipart'
    })
    clipart: Clipart;

    @field()
    option_invert: boolean;

    @field()
    min_sq_inches: number;

    @field(ForeignKeyURIField, {
        model: 'ClipartProductCustomizationGroup'
    })
    customization_group: ClipartProductCustomizationGroup;

    @field(ToManyField, {
        model: 'StockProductSize'
    })
    sizes: Collection<StockProductSize>;

    @field()
    allow_custom_size: boolean;

    @field()
    show_regions: boolean;

    @field()
    max_height;

    @field()
    max_width;

    @field()
    preview: string;

    @field(ToManyField, {
        model: 'TemplateOptionSet'
    })
    options: Collection<TemplateOptionSet>;

    get thumbnail() {
        return this.clipart.clipart;
    }

    override get url(): string {
        if (!this.category) {
            return null;
        }

        return Services.get<NamedRouteService>('$NamedRouteService').reverse('svgcustom:basic', {
            categoryPath: this.category.path,
            productSlug: this.slug
        });
    }

    override get lockRatio(): number {
        let v = super.lockRatio;

        if (isNaN(v) && this.default_height && this.default_height) {
            return this.default_width / this.default_height;
        }

        return v;
    }
}
