import {field} from "../../../core/models/fields/Field";
import {Model} from "../../../core/models/Model";
import {APIResource} from '../../../core/models/APIResource';

export class Font extends Model {
    static override uri:string = '/custom/api/v1/font/:id/';
    static override objects: APIResource<Font>;

    @field()
    family:string;

    @field()
    family_web:string;

    @field()
    name:string;

    @field()
    font: string;

    @field()
    preview: string;

    @field()
    default: boolean;

    get fontFamily() {
        return this.family_web ? this.family_web : this.family;
    }
}
