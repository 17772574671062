import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {PriceField} from '../../../core/models/fields/PriceField';
import {PositiveIntegerField} from '../../../core/models/fields/PositiveNumberField';
import {Material} from './Material';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {APIResource} from '../../../core/models/APIResource';

export class SpecialOffer extends Model {
    static override uri: string = '/custom/api/v1/special-offers/:id/';
    static override objects: APIResource<SpecialOffer>;

    @field()
    enabled: boolean;

    @field()
    name: string;

    @field()
    description: string;

    @field()
    sku: string;

    @field(PriceField)
    unit_price: PriceField;

    @field(PositiveIntegerField)
    quantity: number;

    @field()
    width: number;

    @field()
    height: number;

    @field(ForeignKeyField, {
        model: 'Material',
        readOnly: true
    })
    material: Material;

    @field()
    image: string;
}