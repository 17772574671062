import {Model} from "../../../core/models/Model";
import {field} from "../../../core/models/fields/Field";
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ProcessedFileFolder} from "./ProcessedFileFolder";
import {APIResource} from '../../../core/models/APIResource';

export class ProcessedFileDisplay extends Model {
    static override uri:string = '/processing/api/user/processed-file-display/:id/';
    static override objects: APIResource<ProcessedFileDisplay>;

    @field()
    product: string;

    @field()
    hidden: boolean;

    @field()
    favorite: boolean;

    @field(ForeignKeyField, {
        model: 'ProcessedFileFolder'
    })
    folder: ProcessedFileFolder;
}