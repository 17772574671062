import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {Model} from '../../../core/models/Model';
import {ProcessedFileDisplay} from './ProcessedFileDisplay';
import {APIResource} from '../../../core/models/APIResource';

export class ProcessedFile extends Model {
    static override uri: string = '/processing/api/user/processed-file/:id/';
    static override objects: APIResource<ProcessedFile>;

    @field()
    name: string;

    @field()
    product_id: number;

    @field()
    raster: string;

    @field()
    customer_raster: string;

    @field()
    date_created: string;

    @field(ForeignKeyField, {
        model: 'ProcessedFileDisplay'
    })
    display: ProcessedFileDisplay;

    @field()
    data: any;

    @field()
    orders: string[];

    @field()
    ordered_items: string[];

    @field()
    products: any[];

    @field()
    types: string[];

    @field()
    materials: string[];

    @field()
    width: number;

    @field()
    height: number;

    @field()
    removed: boolean;

    @field()
    download: string;
}
