import {Model, TModelDefinitionList} from '../../../core/models/Model';
import {ProcessedFile} from './ProcessedFile';
import {ProcessedFileDisplay} from './ProcessedFileDisplay';
import {ProcessedFileFolder} from './ProcessedFileFolder';
import {ProofRequest} from './ProofRequest';
import {ProofRequestOption} from './ProofRequestOption';

export default function registerModels(): void {
    const models: TModelDefinitionList = {
        ProcessedFile: ProcessedFile,
        ProcessedFileDisplay: ProcessedFileDisplay,
        ProcessedFileFolder: ProcessedFileFolder,
        ProofRequest: ProofRequest,
        ProofRequestOption: ProofRequestOption
    };

    Model.registerModels(models);
}