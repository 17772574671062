import {
    ConfigurableProductAttributeAbstract,
    ProductAttributeAbstract
} from '../../product/models/abstract/ProductAttributeAbstract';
import {IProduct} from '../../svgcustom/models/IProduct';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {BaseMarketplaceProduct} from './BaseMarketplaceProduct';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {MarketplaceArtwork} from './MarketplaceArtwork';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {Collection} from '../../../core/models/Collection';
import {StockProductSize} from '../../svgcustom/models/StockProductSize';
import {ProductTypeEnum} from '../../svgcustom/models/enums';
import NamedRouteService from '../../../core/services/NamedRouteService';
import {Services} from '../../../core/services/Services';
import {APIResource} from '../../../core/models/APIResource';
import {Tag} from '../../svgcustom/models/Tag';

export class MarketplaceProduct extends ConfigurableProductAttributeAbstract implements IProduct {
    public static override uri: string = '/marketplace/api/v1/product/:id/';
    static override objects: APIResource<MarketplaceProduct>;
    public static readonly $inject: string[] = [
        '$NamedRouteService'
    ];

    @field(ForeignKeyField, {
        model: 'BaseMarketplaceProduct',
        readOnly: true
    })
    public base: BaseMarketplaceProduct;

    @field(ForeignKeyURIField, {
        model: 'MarketplaceArtwork',
        readOnly: true
    })
    public artwork: TForeignKeyURI<MarketplaceArtwork>;

    @field(ToManyField, {
        model: 'StockProductSize',
        readOnly: true
    })
    sizes: Collection<StockProductSize>;

    @field()
    public allow_custom_size: boolean;

    @field()
    public min_sq_inches: number;
    get options() {
        return this.base.options;
    }
    get option_laminate(): boolean {
        return this.base.option_laminate;
    }

    get option_reverse_cut(): boolean {
        return this.base.option_reverse_cut;
    }

    get option_flex_cut(): boolean {
        return this.base.option_flex_cut;
    }

    get _type() {
        return this.base?.type
    }
    set _type(v) {
        return
    }

    get tags(): Collection<Tag> {
        return this.base.tags;
    }

    get url(): string {
        return Services.get<NamedRouteService>('$NamedRouteService').reverse('marketplace:product', {
            categoryPath: this.category ? this.category.path : '',
            productSlug: this.slug
        });
    }

    override get lockRatio(): number {
        let v = super.lockRatio;

        if (isNaN(v) && this.default_height && this.default_height) {
            return this.default_width / this.default_height;
        }

        return v;
    }
}
