import {Model} from "../../../core/models/Model";
import {field} from "../../../core/models/fields/Field";
import {ToManyField} from "../../../core/models/fields/ToManyField";


export class CategoryChild extends Model {
    /*
     * Not a full model, has no resource endpoint. Returned from category
     * resource.
     */

    @field()
    name:string;

    @field()
    name_plural:string;

    @field()
    meta_description:string;

    @field()
    path:string;

    @field()
    image_url:string;

    @field()
    children: any;

    @field()
    hidden: boolean;

    @field()
    sku: string;

    @field()
    order: string;

    @field()
    slug: string;
}