import {Model} from '../../../core/models/Model';
import {PackageSize} from './PackageSize';
import {RetailPackage} from './RetailPackage';
import {Services} from '../../../core/services/Services';
import PriceQuote from '../../products_shared/services/PriceQuote';
import PricingTier from '../../../core/utils/PricingTier';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {APIResource} from '../../../core/models/APIResource';

export default class ConfiguredRetailPackage extends Model {
    static override uri: string = '/packaging/api/v1/configured-package/:id/';
    static override objects: APIResource<ConfiguredRetailPackage>;

    @field(ForeignKeyField, {
        model: 'RetailPackage',
        readOnly: true
    })
    package: RetailPackage;

    @field(ForeignKeyField, {
        model: 'PackageSize',
        readOnly: true
    })
    size: PackageSize

    @field()
    price;

    @field()
    preview;

    @field()
    currency: string;

    pricing: PricingTier[];
    pricing_cache: any = {};
    fetching_quote: boolean;

    priceFor(quantity: number): PricingTier {
        if (!this.pricing) {
            if (!this.fetching_quote) {
                this.fetchQuote();
            }

            return new PricingTier(0, 0);
        }

        // We need to cache this to prevent a infinite loop with dirty value checking
        if (this.pricing_cache[quantity]) {
            return this.pricing_cache[quantity];
        }

        let tier = this.pricing[0];
        for (const t of this.pricing) {
            if (t.quantity <= quantity) {
                tier = t;
            }
        }

        this.pricing_cache[quantity] = new PricingTier(quantity, tier.unitPrice);
        return this.pricing_cache[quantity];
    }

    fetchQuote() {
        this.fetching_quote = true;
        Services.get<PriceQuote>('PriceQuote').quoteRetailPackage(this.package.id, this.size.id).then(
            (data) => {
                this.pricing = data.pricing;
                this.currency = data.currency;
                this.pricing_cache = {};
                this.trigger('sync');
            },
            (error) => {
                return
            }
        )
    }
}