import {field} from '../../../core/models/fields/Field';
import {ElementAbstract} from './abstract/ElementAbstract';
import {IElement} from './IElement';
import {UserFile} from './UserFile';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {APIResource} from '../../../core/models/APIResource';

export class CustomUserFile extends ElementAbstract implements IElement {
    public static override uri: string = '/custom/api/v1/custom-user-file/:id/';
    static override objects: APIResource<CustomUserFile>;

    @field(ForeignKeyField, {
        model: 'UserFile'
    })
    user_file: UserFile;

    selectable: boolean = true;

    get label() {
        if (this.temp_label) {
            return this.temp_label;
        }
        return (this.user_file as UserFile).file_name;
    }

    temp_label;

    set label(l) {
        this.temp_label = l;
    }
}
