import {Collection} from '../../../core/models/Collection';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {ElementAbstract} from './abstract/ElementAbstract';
import {TextAbstract} from './abstract/TextAbstract';
import {Clipart} from './Clipart';
import {ConfiguredDragDropBuyProductClipartRegion} from './ConfiguredDragDropBuyProductClipartRegion';
import {IClipart} from './IClipart';
import {IText} from './IText';
import {APIResource} from '../../../core/models/APIResource';

export class DummyText extends TextAbstract implements IText {
    @field()
    selectable: boolean;
}

export class DummyClipart extends ElementAbstract implements IClipart {
    public readonly show_cut_contour: boolean = false;

    @field(ToManyField, {
        model: 'ConfiguredDragDropBuyProductClipartRegion'
    })
    regions: Collection<ConfiguredDragDropBuyProductClipartRegion>;

    @field()
    selectable: boolean;

    // Not used with ddb tool but still needs to be supported
    use_as_mask: boolean = false;

    @field()
    clipart: Clipart;
}

export class ConfiguredDragDropBuyProductClipart extends ElementAbstract implements IClipart {
    static override uri: string = '/custom/api/v1/configured-ddb-product-clipart/:id/';
    static override objects: APIResource<ConfiguredDragDropBuyProductClipart>;
    public readonly show_cut_contour: boolean = false;

    @field(ForeignKeyField, {
        model: 'Clipart',
        readOnly: true
    })
    clipart: Clipart;

    @field(ToManyField, {
        model: 'ConfiguredDragDropBuyProductClipartRegion'
    })
    regions: Collection<ConfiguredDragDropBuyProductClipartRegion>;

    @field()
    text: string;

    @field()
    selectable: boolean;

    // Not used with ddb tool but still needs to be supported
    use_as_mask: boolean = false;
}
