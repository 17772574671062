import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {Collection} from '../../../core/models/Collection';
import {PackageSize} from './PackageSize';
import {APIResource} from '../../../core/models/APIResource';

export class RetailPackage extends Model {
    static override uri: string = '/packaging/api/v1/package/:id/';
    static override objects: APIResource<RetailPackage>;

    @field()
    name: string;

    @field()
    description: string;

    @field()
    enabled: boolean;

    @field()
    content: string;

    @field()
    content_back: string;

    @field()
    preview: string;

    @field(ToManyField, {
        model: 'PackageSize'
    })
    available_sizes: Collection<PackageSize>;

    @field()
    no_customer_preview: boolean;

    getValidSizeFor(width, height) {
        width = parseFloat(width);
        height = parseFloat(height);

        this.available_sizes.sort((a,b) => {
            return a.max_product_width - b.max_product_width;
        })

        for (const size of this.available_sizes) {
            if (width < size.max_product_width && height < size.max_product_height && width > size.min_product_width && height > size.min_product_height) {
                return size;
            }
        }
        return null;
    }
}
