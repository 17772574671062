import {TemplateOptionSet} from './TemplateOptionSet';
import {TemplateOption} from './TemplateOption';
import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {CustomOption} from './CustomOption';
import {ConfiguredClipartOption} from './ConfiguredClipartOption';
import {ConfiguredMarketplaceOption} from '../../marketplace/models/ConfiguredMarketplaceOption';

export type AbstractProductOptionInstance = typeof CustomOption | typeof ConfiguredClipartOption | typeof ConfiguredMarketplaceOption | typeof ConfiguredMarketplaceOption;

export default abstract class AbstractProductOption extends Model {
    abstract configured: string;

    @field(ForeignKeyField, {
        model: 'TemplateOptionSet',
        readOnly: true
    })
    option_set: TemplateOptionSet;

    @field(ForeignKeyField, {
        model: 'TemplateOption',
        readOnly: true,
        required: true
    })
    option: TemplateOption;

    // This is a hack for vue to get around a circular decency issue
    get option_id(): number {
        return this.option?.id;
    }
    set option_id(v: number) {
        this.option = v ? this.option_set.options.getItemFromID(v) : null;
    }

    public isDisabled(material) {
        if (!material || !this.option_set || !this.option_set['__excluded_materials']) {
            return true;
        }

        for (const excluded_material of this.option_set['__excluded_materials'].resourceUris) {
            if (typeof material == 'string') {
                if (material == excluded_material.uri) {
                    return true
                }
            }
            else {
                if (material.resource_uri == excluded_material.uri) {
                    return true
                }
            }
        }
        return false;
    }
}