import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {APIResource} from '../../../core/models/APIResource';

export class RecommendedProductGroup extends Model {
    public static override uri: string = '/custom/api/v1/recommended-product-group/:id/';
    static override objects: APIResource<RecommendedProductGroup>;

    @field()
    name: string;

    @field()
    primary: boolean;

}