import {Model} from "../../../core/models/Model";
import {field} from "../../../core/models/fields/Field";
import {APIResource} from '../../../core/models/APIResource';


export class BackgroundImage extends Model {
    static override uri:string = '/custom/api/v1/background-image/:id/';
    static override objects: APIResource<BackgroundImage>;

    @field()
    name: string;

    @field()
    image: string;

    @field()
    foreground_image: string;

    @field()
    box_x: number;

    @field()
    box_y: number;

    @field()
    box_width: number;

    @field()
    box_height: number;

    @field()
    box_height_inches: number;
}
