import {Model} from "../../../core/models/Model";
import {field} from "../../../core/models/fields/Field";
import {APIResource} from '../../../core/models/APIResource';

export class Image extends Model {
    static override uri:string = '/gallery/api/v1/image/:id/';
    static override objects: APIResource<Image>;

    @field()
    caption:string;

    @field()
    featured:boolean;

    @field()
    image:string;

    @field()
    name:string;

    @field()
    option_page_order:number;

    @field()
    ppoi:string;

    @field()
    slug:string;

    @field()
    template:string;

    @field()
    thumbnail:string;

    @field()
    date_created: string;

    @field()
    category_name: string;

    @field()
    category_id: string;

    @field()
    product_url: string;

    @field()
    object_id: number;

    @field()
    currency: string;
}
