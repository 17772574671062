import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {APIResource} from '../../../core/models/APIResource';

export class ClipartCategory extends Model {
    static override uri: string = '/custom/api/v1/clipart-category/:id/';
    static override objects: APIResource<ClipartCategory>;

    @field()
    name: string;

    @field()
    slug: string;

    @field()
    display: boolean;

    @field()
    parent: any;

    @field()
    image: string;

    @field()
    shapes: boolean;
}