import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ElementAbstract} from './abstract/ElementAbstract';
import {IElement} from './IElement';
import {APIResource} from '../../../core/models/APIResource';

export class TemplateMarketplace extends ElementAbstract implements IElement {
    static override uri: string = '/custom/api/v1/template-marketplace/:id/';
    static override objects: APIResource<TemplateMarketplace>;

    @field(ForeignKeyField, {
        model: 'MarketplaceArtwork',
        readOnly: true
    })
    artwork: any;

    removed_background: boolean = false;

    get raster() {
        if (this.removed_background && this.artwork.public_raster_remove_bg) {
            return this.artwork.public_raster_remove_bg;
        }

        return this.artwork.public_raster_url;
    }

    get label() {
        return this.artwork.name
    }

    get marketplace() {
        return true;
    }

    get selectable() {
        return true;
    }
    set selectable(v) {
        return;
    }
}
