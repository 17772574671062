import {Collection} from '../../../../core/models/Collection';
import {field} from '../../../../core/models/fields/Field';
import {ToManyField} from '../../../../core/models/fields/ToManyField';
import {IProduct} from '../IProduct';
import {TemplateOptionSet} from '../TemplateOptionSet';
import {ConfigurableProductAttributeAbstract} from '../../../product/models/abstract/ProductAttributeAbstract';
import {Tag} from '../Tag';


export abstract class ProductAbstract extends ConfigurableProductAttributeAbstract implements IProduct {
    @field(ToManyField, {
        model: 'Tag'
    })
    tags: Collection<Tag>;

    @field()
    content_type: string;

    @field()
    option_laminate: boolean;

    @field()
    option_reverse_cut: boolean;

    @field()
    option_flex_cut: boolean;

    override get title(): string {
        return this.meta_title ? this.meta_title : this.name;
    }

    get url(): string {
        return '';
    }

    abstract options: Collection<TemplateOptionSet>;
}
