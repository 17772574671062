import {field} from "../../../core/models/fields/Field";
import {Model} from "../../../core/models/Model";
import {FloatField} from "../../../core/models/fields/FloatField";
import {Services} from '../../../core/services/Services';
import {Http} from '../../../core/services/Http';
import ButtonProcessingState from '../../../core/utils/ButtonProcessingState';
import {IPromise, SimplePromise} from '../../../core/utils/SimplePromise';
import {APIResource} from '../../../core/models/APIResource';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {processRequestError} from '../../../core/utils/utils';
import MessageList from '../../../core/utils/MessageList';

export class UserFile extends Model {
    static override uri:string = '/custom/api/v1/user-file/:id/';
    static override objects: APIResource<UserFile>;

    @field(FloatField, {
        toFixed: 3
    })
    aspect_ratio_y: number;

    @field()
    date_created: string;

    @field()
    failed_to_raster: boolean;

    @field()
    file: string;

    @field()
    file_key: string;

    @field()
    name: string;

    @field()
    raster: string;

    @field()
    height: number;

    @field()
    width: number;

    @field(FloatField, {
        toFixed: 3
    })
    scale_x:number;

    @field(FloatField, {
        toFixed: 3
    })
    scale_y: number;

    @field()
    maintain_proportion: boolean;

    @field()
    transparent_white: boolean;

    @field()
    is_vector: boolean;

    @field()
    multiple_artboards: boolean;

    @field(ForeignKeyURIField, {
        model: 'UserFile'
    })
    original_background_file: TForeignKeyURI<UserFile>;

    get preview() {
        if(this.raster) {
            return this.raster;
        }
        return '/static/img/preview-not-available.png';
    }

    get file_name() {
        const reFileName = /\/([^\/]*)$/;
        let fileName: string = null;

        if(this.name) {
            let r = reFileName.exec(this.name);
            fileName = r && r.length > 0 ? r[1] : this.name
        } else if(this.file)
            fileName = reFileName.exec(this.file)[1];
        return fileName;
    }

    remove($event?) {
        if (!this.id) {
            return;
        }

        let deferred = SimplePromise.defer<void>();

        let state = new ButtonProcessingState($event);
        state.process();

        Services.get<Http>('$http').request({
            url: '/custom/api/v1/remove-user-file/',
            method: 'POST',
            data: {
                id: this.id
            }
        }).then(() => {
            state.resolved();
            deferred.resolve();
        }, () => {
            state.resolved();
            deferred.resolve();
        })

        return deferred.promise;
    }

    public toggleRemoveBackground(): IPromise<UserFile> {
        if (this.original_background_file) {
            return this.replaceBackground();
        }
        else {
            return this.removeBackground();
        }
    }

    public removeBackground(): IPromise<UserFile> {
        let deferred = SimplePromise.defer<UserFile>();

        let u = Services.get<typeof UserFile>('UserFile').objects.get({
            original_background_file: this.id
        });

        u.$promise.then(() => {
            deferred.resolve(u);
        }, () => {
            Services.get<Http>('$http').request({
                method: 'POST',
                url: '/custom/api/v1/remove-bg/',
                data: {
                    image_url: this.raster,
                    preview: true,
                    original_file_id: this.id
                }
            }).then((response) => {
                let u = UserFile.objects.get({
                    id: response.data['converted_file']
                });

                u.$promise.then(() => {
                    deferred.resolve(u);
                }, (error) => {
                    deferred.reject(error)
                });
            }, (error) => {
                deferred.reject(error);
            })
        })

        return deferred.promise;
    }

    public replaceBackground(): IPromise<UserFile> {
        let deferred = SimplePromise.defer<UserFile>();
        let u = Services.get<typeof UserFile>('UserFile').objects.get({
            id: this.getField<TForeignKeyURI<UserFile>>('original_background_file').id
        });

        u.$promise.then(() => {
            deferred.resolve(u);
        }, (error) => {
            deferred.reject(error);
        })

        return deferred.promise;
    }
}
