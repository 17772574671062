import {field} from "../../../core/models/fields/Field";
import {Model} from "../../../core/models/Model";
import {ToManyField} from "../../../core/models/fields/ToManyField";
import {FloatField} from '../../../core/models/fields/FloatField';
import {TemplateSize} from './TemplateSize';
import {Collection} from '../../../core/models/Collection';
import {APIResource} from '../../../core/models/APIResource';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {TemplateSide} from './TemplateSide';
import {Color} from './Color';
import {ColorField} from '../../../core/models/fields/ColorField';
import {PositiveIntegerField} from '../../../core/models/fields/PositiveNumberField';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {ClipTypeEnum} from './enums';

export class TemplateShape extends Model {
    static override uri:string = '/custom/api/v1/template-shape/:id/';
    static override objects: APIResource<TemplateShape>;

    @field()
    name:string;

    @field()
    button_selected:string;

    @field()
    button_unselected:string;

    @field()
    button_hover: string;

    @field()
    clip_width: number;

    @field()
    clip_height: number;

    @field(FloatField, {
        toFixed: 3
    })
    clip_radius: number;

    @field()
    clip_path: string;

    @field()
    clip_type: ClipTypeEnum;

    @field()
    locked_shape_size: boolean;

    @field()
    fixed_size: boolean;

    @field()
    fixed_width: number;

    @field()
    fixed_height: number;

    @field()
    order: string;

    @field()
    show_material_backing: boolean;

    @field()
    standard_size_ratio: string;

    @field(ToManyField, {
        model: 'TemplateSize'
    })
    sizes: Collection<TemplateSize>;

    @field()
    default: boolean;

    @field(ForeignKeyField, {
        model: 'TemplateSide',
        readOnly: true
    })
    side: TemplateSide;

    @field(ForeignKeyField, {
        model: 'Color',
        readOnly: true
    })
    background_shape_fill: Color;

    @field(ColorField)
    background_shape_custom_fill_color: string;

    @field()
    background_shape_border: boolean;

    @field(PositiveIntegerField, {
        max: 200
    })
    background_shape_border_size: number;

    @field(PositiveIntegerField, {
        max: 200
    })
    background_shape_border_margin: number;

    @field(ForeignKeyField, {
        model: 'Color',
        readOnly: true
    })
    background_shape_border_color: Color;

    @field(ColorField)
    background_shape_custom_border_color: string;

    @field()
    background_shape_border_roundness: number;

    @field(ForeignKeyURIField, {
        model: 'Color',
        readOnly: true
    })
    background_shape_contour_color: TForeignKeyURI<Color>;

    @field()
    share_attributes: boolean;

    @field()
    tag: string;

    get standardSizeRatio(): number {
        if (this.standard_size_ratio == null || this.standard_size_ratio === '') {
            return Number.NaN;
        }

        if (this.standard_size_ratio.includes(':')) {

            const parts = this.standard_size_ratio.split(':');

            if (parts.length === 2) {
                const width: number = Number(parts[0]);
                const height: number = Number(parts[1]);

                return width / height;
            }
            else {
                return Number.NaN;
            }
        }
        return parseFloat(this.standard_size_ratio);
    }

    loadDefaultsFromTemplate() {

    }

    get background_shape_fill_code() {
        if (this.background_shape_custom_fill_color) {
            return this.background_shape_custom_fill_color;
        }
        if (this.background_shape_fill) {
            return this.background_shape_fill.fillValue;
        }
        return null;
    }

    get background_shape_border_color_code() {
        if (this.background_shape_custom_border_color) {
            return this.background_shape_custom_border_color;
        }
        if (this.background_shape_border_color) {
            return this.background_shape_border_color.fillValue;
        }
        return null;
    }
}
