import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {Clipart} from './Clipart';
import {Color} from './Color';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {TemplateSize} from './TemplateSize';
import {ColorCollection} from '../collections/ColorCollection';
import {RecommendedProductGroup} from './RecommendedProductGroup';
import {APIResource} from '../../../core/models/APIResource';

export class RecommendedProduct extends Model {
    public static override uri: string = '/custom/api/v1/recommended-product/:id/';
    static override objects: APIResource<RecommendedProduct>;

    @field(ForeignKeyField, {
        model: 'Clipart'
    })
    clipart: Clipart;

    @field(ForeignKeyField, {
        model: 'Clipart'
    })
    overlay: Clipart;

    @field(ForeignKeyField, {
        model: 'Clipart'
    })
    clipping_mask: Clipart;

    @field(ToManyField, {
        model: 'Color',
        readOnly: true
    })
    colors: ColorCollection;

    @field(ToManyField, {
        model: 'TemplateSize',
        readOnly: true
    })
    sizes: TemplateSize[];

    @field(ForeignKeyField, {
        model: 'RecommendedProductGroup'
    })
    group: RecommendedProductGroup;

    @field(ForeignKeyField, {
        model: 'Color'
    })
    default_color: Color;

    @field()
    icon: string;

    @field()
    x: number;

    @field()
    y: number;

    @field()
    width: number;

    @field()
    height: number;

    @field()
    product_name: string;

    @field()
    canvas_width: number;

    @field()
    canvas_height: number;

    @field()
    disable_colors: boolean;
}