import {field} from '../../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../../core/models/fields/ForeignKeyField';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../../core/models/fields/ForeignKeyURIField';
import {PositiveIntegerField} from '../../../../core/models/fields/PositiveNumberField';
import {Color} from '../Color';
import {SymbolType, TextAlignment} from '../enums';
import {Font} from '../Font';
import {TextPath} from '../TextPath';
import {ColorField} from '../../../../core/models/fields/ColorField';
import {VariableDataTextElementAbstract} from './VariableDataTextElementAbstract';

export class TextAbstract extends VariableDataTextElementAbstract {

    @field(ForeignKeyURIField, {
        model: 'Font'
    })
    font: TForeignKeyURI<Font>;

    @field()
    size: number;

    @field(ForeignKeyField, {
        model: 'Color',
        readOnly: true
    })
    color: Color;

    @field(ColorField)
    custom_color: string;

    @field(ForeignKeyField, {
        model: 'TextPath',
        readOnly: true
    })
    path: TextPath;

    @field()
    text_alignment: TextAlignment;

    @field(ForeignKeyField, {
        model: 'Color',
        readOnly: true
    })
    stroke_color: Color;

    @field(ColorField)
    custom_stroke_color: string;

    @field(PositiveIntegerField)
    stroke_width: number;

    @field()
    path_scale: number;

    // Only allow one of two options to be set
    get _custom_color() {
        return this['__custom_color'].value;
    }
    set _custom_color(value) {
        if (value) {
            this.color = null;
        }
        this['__custom_color'].value = value;
        this['__custom_color'].clean();
    }
    get _color() {
        return this['__color'].value;
    }
    set _color(value) {
        if (value) {
            this.custom_color = null;
        }
        this['__color'].value = value;
        this['__color'].clean();
    }

    // Only allow one of two options to be set
    get _custom_stroke_color() {
        return this['__custom_stroke_color'].value;
    }
    set _custom_stroke_color(value) {
        if (value) {
            this.stroke_color = null;

            if (!this.stroke_width) {
                this.stroke_width = 5;
            }
        }
        this['__custom_stroke_color'].value = value;
        this['__custom_stroke_color'].clean();
    }
    get _stroke_color() {
        return this['__stroke_color'].value;
    }
    set _stroke_color(value) {
        if (value) {
            this.custom_stroke_color = null;
        }
        this['__stroke_color'].value = value;
        this['__stroke_color'].clean();
    }

    get label(): string {
        switch (this.symbol) {
            case SymbolType.NUMBER_RANGE:
                return `${this.symbol_range_start} - ${this.symbol_range_end}`;
            case SymbolType.CSV_LIST:
                if (this.text.length > 25) {
                    `${this.text.slice(0, 25)}...`;
                }
                return this.text;
        }

        return this.text;
    }

    get color_code() {
        if (this.custom_color) {
            return this.custom_color;
        }
        if (this.color) {
            return this.color.fillValue;
        }
        return null;
    }

    get stroke_color_code() {
        if (this.custom_stroke_color) {
            return this.custom_stroke_color;
        }
        if (this.stroke_color) {
            return this.stroke_color.fillValue;
        }
        return null;
    }
}
