import {Field} from './Field';
import {Price} from '../../utils/Price';

export class PriceField extends Field {
    override set value(data: any) {
        this.setValue(new Price(data));
    }

    override get value() {
        return this.getValue();
    }

    override getPostData() {
        return this.value.value;
    }
}
