import {Collection} from '../../../core/models/Collection';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {ElementAbstract} from './abstract/ElementAbstract';
import {Clipart} from './Clipart';
import {IClipart} from './IClipart';
import {TemplateClipartRegion} from './TemplateClipartRegion';
import {APIResource} from '../../../core/models/APIResource';

export class TemplateClipart extends ElementAbstract implements IClipart {
    public static override uri: string = '/custom/api/v1/template-clipart/:id/';
    static override objects: APIResource<TemplateClipart>;

    @field(ForeignKeyField, {
        model: 'Clipart'
    })
    clipart: Clipart;

    @field()
    name: string;

    @field(ToManyField, {
        model: 'TemplateClipartRegion'
    })
    regions: Collection<TemplateClipartRegion>;

    @field()
    template_clipart: boolean;

    @field()
    use_as_mask: boolean;

    @field()
    x_scale_mod: number;

    @field()
    y_scale_mod: number;

    @field()
    selectable: boolean;

    @field()
    top_layer: boolean;

    @field()
    hide: boolean;

    @field()
    show_cut_contour: boolean;

    override get root() {
        return this;
    }

    get label(): string {
        // Return clipart.name, or Clipart Element if there's none
        return this.clipart.name ? this.clipart.name : 'Clipart Element';
    }
}
