import registerGalleryModels from '../../ts/apps/gallery/models/init';
import registerMarketplaceModels from '../../ts/apps/marketplace/models/init';
import registerCategoryModels from '../../ts/apps/category/models/init';
import registerCustomModels from '../../ts/apps/svgcustom/models/init';
import registerProcessingModels from '../../ts/apps/processing/models/init';
import registerPackagingModels from '../../ts/apps/packaging/models/init';
import {IntegrationExtra} from '../../ts/core/utils/IntegrationExtra';

export default class CustomCommonIntegration extends IntegrationExtra {
    setupApps(): void {
    }

    setupModels(): void {
        registerGalleryModels();
        registerMarketplaceModels();
        registerCategoryModels();
        registerCustomModels();
        registerProcessingModels();
        registerPackagingModels();
    }

    setupServices(): void {

    }
}