import {TextAbstract} from './abstract/TextAbstract';
import {Color} from './Color';
import {IText} from './IText';
import {TextAlignment} from "./enums";
import {APIResource} from '../../../core/models/APIResource';

export class CustomText extends TextAbstract implements IText {
    static override uri: string = '/custom/api/v1/custom-text/:id/';
    static override objects: APIResource<CustomText>;
    selectable: boolean = true;

    override get label(): string {
        return this.text ? this.text : 'Text Element';
    }

    /**
     * strokeColor getter/setter are used by the color-selector component
     */
    set strokeColor(color) {
        // UX: See the stroke as soon as you select a color if the current
        // stroke width is 0
        if (!this.stroke_width) {
            this.stroke_width = 5;
        }
        this.stroke_color = color;
    }

    get strokeColor(): Color {
        return this.stroke_color as Color;
    }

    removeStroke() {
        this.stroke_width = 0;
        this.stroke_color = null;
    }

    get hasStroke() {
        return this.stroke_width > 0 && this.stroke_color != null;
    }

    get alignmentLabel(){
        if(this.text_alignment == TextAlignment.CENTER)
            return 'Center';
        else if(this.text_alignment == TextAlignment.LEFT)
            return 'Left';
        else if(this.text_alignment == TextAlignment.RIGHT)
            return 'Right';
        return
    }

    pathName(path){
        if(path)
            return path.name;
        return ""
    }
}
