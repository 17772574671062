import {field} from '../../../core/models/fields/Field';
import AbstractProductOption from './AbstractProductOption';
import {APIResource} from '../../../core/models/APIResource';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {Custom} from './Custom';

export class CustomOption extends AbstractProductOption {
    static override uri: string = '/custom/api/v1/custom-option/:id/';
    static override objects: APIResource<CustomOption>;

    @field(ForeignKeyURIField, {
        model: 'Custom',
        readOnly: true
    })
    configured: TForeignKeyURI<Custom>;

    get custom() {
        return this.configured;
    }
    set custom(v) {
        this.configured = v;
    }
}
