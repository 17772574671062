import {field} from '../../../../core/models/fields/Field';
import {ElementAbstract} from './ElementAbstract';
import {SymbolType} from '../enums';
import {QuantityType} from './ConfiguredProductAbstract';
import MessageList from '../../../../core/utils/MessageList';

export enum SymbolTypeName {
    NORMAL_TEXT = 'Single Element (Normal)',
    NUMBER_RANGE = 'Number Range',
    CSV_TEXT = 'Comma Separated List'
}

export abstract class VariableDataElementAbstract extends ElementAbstract {
    @field()
    symbol: SymbolType;

    abstract range: number|null;

    available_types: SymbolTypeName[] = [
        SymbolTypeName.NORMAL_TEXT,
        SymbolTypeName.NUMBER_RANGE,
        SymbolTypeName.CSV_TEXT
    ];

    get definesQuantity(): boolean {
        switch (this.symbol) {
            case SymbolType.NUMBER_RANGE:
                return true;
            case SymbolType.VARIABLE_DATA:
                return false;
            case SymbolType.CSV_LIST:
                return true;
            default:
                return false;
        }
    }

    get quantity_type(): QuantityType {
        switch (this.symbol) {
            case SymbolType.NUMBER_RANGE:
                return QuantityType.RANGE;
            case SymbolType.CSV_LIST:
                return QuantityType.RANGE;
            default:
                return null;
        }
    }

    override validate(): MessageList {
        super.validate();

        if (this.symbol == SymbolType.NUMBER_RANGE && !this.range) {
            this._errors.add('range', 'Not a valid number range.');
        }

        return this._errors;
    }

    onSymbolChange(old_value: SymbolType, new_value: SymbolType): void {}

    set symbol_by_name(v: SymbolTypeName) {
        let _symbol = this.symbol;

        if (v == SymbolTypeName.NUMBER_RANGE) {
            this.symbol = SymbolType.NUMBER_RANGE;
        }
        if (v == SymbolTypeName.CSV_TEXT) {
            this.symbol = SymbolType.CSV_LIST;
        }
        if (v == SymbolTypeName.NORMAL_TEXT) {
            this.symbol = SymbolType.NONE;
        }

        if (this.symbol != _symbol) {
            this.onSymbolChange(_symbol, this.symbol);
        }
    }

    get symbol_by_name() {
        if (this.symbol == SymbolType.CSV_LIST) {
            return SymbolTypeName.CSV_TEXT
        }
        if (this.symbol == SymbolType.NUMBER_RANGE) {
            return SymbolTypeName.NUMBER_RANGE;
        }
        if (this.symbol == SymbolType.NONE) {
            return SymbolTypeName.NORMAL_TEXT
        }
        return SymbolTypeName.NORMAL_TEXT;
    }
}
