import {Model, TModelDefinitionList} from '../../../core/models/Model';
import {Image} from "./Image";

export default function registerModels(): void {
    const models: TModelDefinitionList = {
        'Image': Image,
    };

    Model.registerModels(models);
}
