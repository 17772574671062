import {ProductSizeAbstract} from './abstract/ProductSizeAbstract';
import {field} from '../../../core/models/fields/Field';
import {Collection} from '../../../core/models/Collection';
import {Color} from './Color';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {APIResource} from '../../../core/models/APIResource';

export class TemplateSize extends ProductSizeAbstract {
    public static override uri: string = '/custom/api/v1/template-size/:id/';
    static override objects: APIResource<TemplateSize>;

    @field(ToManyField, {
        model: 'Color'
    })
    disabled_colors: Collection<Color>;

    @field()
    material_price: boolean;
}
