import {Collection} from '../../../../core/models/Collection';
import {field} from '../../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../../core/models/fields/ForeignKeyField';
import {ToManyField} from '../../../../core/models/fields/ToManyField';
import {Model} from '../../../../core/models/Model';
import {Category} from '../../../category/models/Category';
import {Material} from '../../../svgcustom/models/Material';
import {FloatField} from '../../../../core/models/fields/FloatField';
import {ProductTypeEnum} from '../../../svgcustom/models/enums';
import {APIResource} from '../../../../core/models/APIResource';
import {TemplateOptionSet} from '../../../svgcustom/models/TemplateOptionSet';

/*
    Used with Product, Template, ClipartProduct, MarketplaceProduct
 */
export class ProductAttributeAbstract extends Model {
    static override objects: APIResource<ProductAttributeAbstract>;

    @field()
    public: boolean;

    @field()
    name: string;

    @field()
    slug: string;

    @field()
    sku: string;

    @field()
    description: string;

    @field()
    short_description: string;

    @field()
    meta_title: string;

    @field()
    meta_description: string;

    @field()
    meta_keywords: string;

    @field()
    minimum_quantity: number;

    @field()
    default_quantity: number;

    @field()
    is_licensed: boolean;

    @field(ForeignKeyField, {
        model: 'Category',
        readOnly: true
    })
    category: Category;

    @field(ToManyField, {
        model: 'Category',
        readOnly: true
    })
    categories: Collection<Category>;

    @field()
    lock_ratio: string;

    @field()
    remaining_stock: number;

    @field()
    allow_backorders: boolean;

    @field()
    gtin: string;

    @field()
    upi: string;

    @field()
    image_url: string;

    @field()
    thumbnail_url: string;

    get title(): string {
        return this.meta_title ? this.meta_title : this.name;
    }

    get lockRatio(): number {
        if (this.lock_ratio == null || this.lock_ratio === '') {
            return Number.NaN;
        }

        if (this.lock_ratio.includes(':')) {

            const parts = this.lock_ratio.split(':');

            if (parts.length === 2) {
                const width: number = Number(parts[0]);
                const height: number = Number(parts[1]);

                if (!width || !height) {
                    return Number.NaN;
                }

                return width / height;
            }
            else {
                return Number.NaN;
            }
        }

        return parseFloat(this.lock_ratio);
    }

    get hasLockRatio(): boolean {
        return !isNaN(this.lockRatio);
    }

    content_type_name: string;
}

/*
    Used with Template, ClipartProduct, MarketplaceProduct

    Sub classes must implement materials but we cant make this abstract due to type issues with factory pattern
 */
export class ConfigurableProductAttributeAbstract extends ProductAttributeAbstract {
    @field(ToManyField, {
        model: 'Material',
        readOnly: true
    })
    materials: Collection<Material>;

    @field(FloatField)
    public default_width: number;

    @field(FloatField)
    public default_height: number;

    @field()
    limited_quantity: number;

    @field()
    type: ProductTypeEnum;

    //options: Collection<TemplateOptionSet>;
}
