import {field} from "../../../core/models/fields/Field";
import {TextAbstract} from "./abstract/TextAbstract";
import {IText} from "./IText";
import {APIResource} from '../../../core/models/APIResource';


export class TemplateText extends TextAbstract implements IText {
    static override uri:string = '/custom/api/v1/template-text/:id/';
    static override objects: APIResource<TemplateText>;

    @field()
    name: string;

    @field()
    template_text: boolean;

    @field()
    selectable: boolean;

    override get root() {
        return this;
    }
}
