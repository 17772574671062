
export function arraysEqual(...arrays: any[]) {
    /*
     * Modified script from https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
     */
    for (const array of arrays) {
        for (const array2 of arrays) {
            // Skip if they're the same array
            if (array === array2)
                continue;

            // if the other array is a falsy value, return
            if (!array)
                return false;

            // compare lengths - can save a lot of time
            if (array2.length != array.length)
                return false;

            for (const i in array2) {
                // Check if we have nested arrays
                if (array[i] instanceof Array && array[i] instanceof Array) {
                    // recurse into the nested arrays
                    if (!arraysEqual(array, array2))
                        return false;
                }
                else if (array2[i] !== array[i]) {
                    // Warning - two different object instances will never be equal: {x:20} != {x:20}
                    return false;
                }
            }
        }
    }

    return true;
}

export function zip(...arrays: any[]): any[] {
    arrays = arrays || [];
    const zipped: any[] = [];
    let shortestLength: number = null;
    for (const array of arrays) {
        if (array && (shortestLength === null || array.length < shortestLength))
            shortestLength = array.length;
    }

    if (shortestLength === null)
        return [];

    for (let i: number = 0; i < shortestLength; i++) {
        const row: any[] = [];
        for (const array of arrays) {
            row.push(array[i]);
        }
        zipped.push(row);
    }

    return zipped;
}
