import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {APIResource} from '../../../core/models/APIResource';

export class StockProductSize extends Model {
    public static override uri: string = '/custom/api/v1/stock-product-size/:id/';
    static override objects: APIResource<StockProductSize>;

    @field()
    sqinch: number;

    @field()
    name: string;

    @field()
    default: boolean;
}
