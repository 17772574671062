import {ConfiguredProductAbstract} from '../../svgcustom/models/abstract/ConfiguredProductAbstract';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {BaseMarketplaceProduct} from './BaseMarketplaceProduct';
import {MarketplaceArtwork} from './MarketplaceArtwork';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {Collection} from '../../../core/models/Collection';
import {ShutterstockProductOption} from './ShutterstockProductOption';
import {APIResource} from '../../../core/models/APIResource';


export class ConfiguredShutterstockProduct extends ConfiguredProductAbstract {
    public static override uri: string = '/shutterstock/api/v1/shutterstock-product/:id/';
    static override objects: APIResource<ConfiguredShutterstockProduct>;
    override productClass: string = 'shutterstock';

    @field(ForeignKeyField, {
        model: 'BaseMarketplaceProduct',
        readOnly: true
    })
    base: BaseMarketplaceProduct;

    @field(ForeignKeyField, {
        model: 'MarketplaceArtwork',
        readOnly: true
    })
    artwork: MarketplaceArtwork;

    @field()
    shutterstock_image_id: string;

    @field()
    instructions: string;

    @field(ToManyField, {
        model: 'ShutterstockProductOption'
    })
    override options: Collection<ShutterstockProductOption>;
    override get product() {
        return this.base;
    }
    override set product(v) {
        this.base = v;
    }

    get allow_custom_size() {
        return true;
    }
}