import {field} from '../../../../core/models/fields/Field';
import {
    ForeignKeyURIField,
    TForeignKeyURI
} from '../../../../core/models/fields/ForeignKeyURIField';
import {Model} from '../../../../core/models/Model';
import {Color} from '../Color';
import {ColorField} from '../../../../core/models/fields/ColorField';
import {ForeignKeyField} from '../../../../core/models/fields/ForeignKeyField';

export class RegionAbstract extends Model {

    @field(ForeignKeyField, {
        model: 'Color',
        readOnly: true
    })
    color: Color;

    @field(ColorField)
    custom_color: string;

    @field()
    stroke_width: number;

    get color_code() {
        if (this.custom_color) {
            return this.custom_color;
        }
        if (this.color) {
            return this.color.fillValue;
        }
        return null;
    }

    // Only allow one of two
    get _color() {
        return this['__color'].value
    }
    set _color(value) {
        if (value) {
            this.custom_color = null;
        }
        this['__color'].value = value;
        this['__color'].clean();
    }
    get _custom_color() {
        return this['__custom_color'].value
    }
    set _custom_color(value) {
        if (value) {
            this.color = null;
        }
        this['__custom_color'].value = value;
        this['__custom_color'].clean();
    }
}
