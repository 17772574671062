import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {APIResource} from '../../../core/models/APIResource';

export class MarketplaceCompany extends Model {
    public static override uri: string = '/marketplace/api/v1/company/:id/';
    static override objects: APIResource<MarketplaceCompany>;

    @field()
    public name: string;

    @field()
    public slug: string;

    @field()
    public logo: string;

    @field()
    public slogan: string;
}
