import {field} from '../../../core/models/fields/Field';
import {Model} from '../../../core/models/Model';
import {APIResource} from '../../../core/models/APIResource';

export class UsesLink extends Model {
    static override uri: string = '/products/api/v1/uses-link/:id/';
    static override objects: APIResource<UsesLink>;

    @field()
    name: string;

    @field()
    sku: string;

    @field()
    url: string;

    @field()
    image_url: string;

    @field()
    object_id: number;

    @field()
    use: string;
}