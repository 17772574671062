import {field} from "../../../core/models/fields/Field";
import {Model} from "../../../core/models/Model";
import {APIResource} from '../../../core/models/APIResource';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {Color} from './Color';
import {ColorField} from '../../../core/models/fields/ColorField';
import {PositiveIntegerField} from '../../../core/models/fields/PositiveNumberField';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {TemplateShape} from './TemplateShape';
import {Services} from '../../../core/services/Services';
import {Custom} from './Custom';

export class CustomShape extends Model {
    static FIELDS_TO_COPY = [
        'background_shape_fill',
        'background_shape_custom_fill_color',
        'background_shape_border',
        'background_shape_border_size',
        'background_shape_border_margin',
        'background_shape_border_color',
        'background_shape_custom_border_color',
        'background_shape_border_roundness',
        'background_shape_contour_color',
        'clip_radius'
    ]
    static SHARED_ATTRIBUTES = [
        'clip_radius',
    ];
    static CARRY_OVER_ATTRIBUTES = [
        'background_shape_fill',
        'background_shape_custom_fill_color',
        'background_shape_border_color',
        'background_shape_custom_border_color',
        'background_shape_contour_color',
    ]
    static override uri:string = '/custom/api/v1/custom-shape/:id/';
    static override objects: APIResource<CustomShape>;

    @field(ForeignKeyField, {
        model: 'TemplateShape',
        readOnly: true
    })
    template_shape: TemplateShape;

    @field(ForeignKeyField, {
        model: 'Color',
        readOnly: true
    })
    background_shape_fill: Color;

    @field(ColorField)
    background_shape_custom_fill_color: string;

    @field()
    background_shape_border: boolean;

    @field(PositiveIntegerField, {
        max: 200
    })
    background_shape_border_size: number;

    @field(PositiveIntegerField, {
        max: 200
    })
    background_shape_border_margin: number;

    @field(ForeignKeyField, {
        model: 'Color',
        readOnly: true
    })
    background_shape_border_color: Color;

    @field(ColorField)
    background_shape_custom_border_color: string;

    @field()
    background_shape_border_roundness: number;

    @field(ForeignKeyURIField, {
        model: 'Color',
        readOnly: true
    })
    background_shape_contour_color: TForeignKeyURI<Color>;

    @field()
    clip_radius: number;

    private configured: Custom;
    bindToConfigured(configured: Custom) {
        this.configured = configured;

        if (this.configured.template.show_material_color) {
            this.bind('change:background_shape_fill', () => {
                if (this.configured.material_color != this.background_shape_fill) {
                    this.configured.material_color = this.background_shape_fill;
                }
            })
        }
    }

    get width() {
        if (this.fixed_size) {
            return this.fixed_width;
        }
        return this.configured.width;
    }
    set width(v) {
        this.configured.width = v;
    }

    get height() {
        if (this.fixed_size) {
            return this.fixed_height;
        }
        return this.configured.height;
    }
    set height(v) {
        this.configured.height = v;
    }

    loadDefaultsFromTemplate(defaults?: any) {
        for (const field of CustomShape.FIELDS_TO_COPY) {
            this[field] = this.template_shape[field];
        }
        if (defaults) {
            for (const field of Object.keys(defaults)) {
                this[field] = defaults[field];
            }
        }
    }

    // Pass though functions to allow CustomShape and TemplateShape objects to be used nearly interchangeably in some functions
    get standardSizeRatio(): number {
        return this.template_shape.standardSizeRatio;
    }

    get fixed_height() {
        return this.template_shape.fixed_height;
    }

    get fixed_width() {
        return this.template_shape.fixed_width;
    }

    get fixed_size() {
        return this.template_shape.fixed_size;
    }

    get locked_shape_size() {
        return this.template_shape.locked_shape_size;
    }

    get clip_width() {
        return this.template_shape.clip_width;
    }

    get clip_height() {
        return this.template_shape.clip_height;
    }

    set side(v) {}
    get side() {
        return this.template_shape.side;
    }

    set x_scale_inverted(v) {}
    get x_scale_inverted(): boolean {
        return false;
    }

    set y_scale_inverted(v) {}
    get y_scale_inverted(): boolean {
        return false;
    }

    get background_shape_fill_code() {
        if (this.background_shape_custom_fill_color) {
            return this.background_shape_custom_fill_color;
        }
        if (this.background_shape_fill) {
            return this.background_shape_fill.fillValue;
        }
        return null;
    }

    get background_shape_border_color_code() {
        if (this.background_shape_custom_border_color) {
            return this.background_shape_custom_border_color;
        }
        if (this.background_shape_border_color) {
            return this.background_shape_border_color.fillValue;
        }
        return null;
    }

    carryOverData() {
        let data = {};
        for (const field of CustomShape.CARRY_OVER_ATTRIBUTES) {
            if (this[field]) {
                data[field] = this[field];
            }
        }
        return data;
    }

    // Only allow one of two
    get _background_shape_fill() {
        return this['__background_shape_fill'].value
    }
    set _background_shape_fill(value) {
        if (value) {
            this.background_shape_custom_fill_color = null;
        }
        this['__background_shape_fill'].value = value;
        this['__background_shape_fill'].clean();
    }

    get _background_shape_custom_fill_color() {
        return this['__background_shape_custom_fill_color'].value
    }
    set _background_shape_custom_fill_color(value) {
        if (value) {
            this.background_shape_fill = null;
        }
        this['__background_shape_custom_fill_color'].value = value;
        this['__background_shape_custom_fill_color'].clean();
    }

    // Only allow one of two
    get _background_shape_border_color() {
        return this['__background_shape_border_color'].value
    }
    set _background_shape_border_color(value) {
        if (value) {
            this.background_shape_custom_border_color = null;
        }
        this['__background_shape_border_color'].value = value;
        this['__background_shape_border_color'].clean();
    }

    get _background_shape_custom_border_color() {
        return this['__background_shape_custom_border_color'].value
    }
    set _background_shape_custom_border_color(value) {
        if (value) {
            this.background_shape_border_color = null;
        }
        this['__background_shape_custom_border_color'].value = value;
        this['__background_shape_custom_border_color'].clean();
    }
}
