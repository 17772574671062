import {field} from "../../../core/models/fields/Field";
import {Model} from "../../../core/models/Model";
import {APIResource} from '../../../core/models/APIResource';

export enum BarCodeType {
    CODE39 = 'code39',
    CODE128 = 'code128',
    PZN = 'pzn',
    EAN13 = 'ean13',
    EAN8 = 'ean8',
    JAN = 'jan',
    ISBN13 = 'isbn13',
    ISBN10 = 'isbn10',
    ISSN = 'issn',
    UPCA = 'upca'
}

export const BarCodeTypeDisplayMap = {
    'code39': 'Code 39',
    'code128': 'Code 128',
    'pzn': 'PZN',
    'ean13': 'EAN-13',
    'ean8': 'EAN-8',
    'jan': 'JAN',
    'isbn13': 'ISBN-13',
    'isbn10': 'ISBN-10',
    'issn': 'ISSN',
    'upca': 'UPCA',
}

export enum QRCodeType {
    WEBSITE= 0,
    CONTACT = 1,
    TEXT_MESSAGE = 2,
    CUSTOM = 3
}

export const QRCodeTypeDisplayMap = {
    0: 'Website QR Code',
    1: 'Contact QR Code',
    2: 'Text Message QR Code',
    3: 'Custom QR Code'
}

export enum CodeType {
    QR_CODE = 0,
    BAR_CODE = 1
}

export class QRCode extends Model {
    /*
        QR Codes and Bar Codes and any other type of code we want to add

        Its a nightmare to rename the class so its staying "QRCode" for now
     */
    static override uri: string = '/custom/api/v1/qrcode/:id/';
    static override objects: APIResource<QRCode>;

    @field()
    code_type: CodeType;

    @field()
    barcode_type: BarCodeType;

    @field()
    qrcode_type: QRCodeType;

    @field()
    svg: string;

    @field()
    data: any;

    @field()
    data_json: any;

    @field()
    size: number;

    @field()
    min_size: number;

    @field()
    date_created: string;

    get barcode_type_name() {
        return this.barcode_type ? BarCodeTypeDisplayMap[this.barcode_type] : 'Unknown';
    }
}
