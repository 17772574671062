import {TabSegment} from "./TabSegment";
import {Tab} from "./Tab";
import {field} from "../../../core/models/fields/Field";
import {ForeignKeyField} from "../../../core/models/fields/ForeignKeyField";
import {Model} from "../../../core/models/Model";
import {APIResource} from '../../../core/models/APIResource';


export class TemplateTab extends Model {
    static override uri:string = '/custom/api/v1/template-tab/:id/';
    static override objects: APIResource<TemplateTab>;

    @field()
    order:number;

    @field()
    template:string;

    @field(ForeignKeyField, {
        model:'TabSegment'
    })
    segment:TabSegment;

    @field(ForeignKeyField, {
        model:'Tab'
    })
    tab:Tab;
}
