import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {Model} from '../../../core/models/Model';
import {Clipart} from './Clipart';
import {DragDropBuyProduct} from './DragDropBuyProduct';
import {APIResource} from '../../../core/models/APIResource';

export class DragDropBuyProductClipart extends Model {
    static override uri: string = '/custom/api/v1/ddb-product-clipart/:id/';
    static override objects: APIResource<DragDropBuyProductClipart>;

    @field(ForeignKeyURIField, {
        model: 'DragDropBuyProduct'
    })
    base: TForeignKeyURI<DragDropBuyProduct>;

    @field(ForeignKeyField, {
        model: 'Clipart'
    })
    clipart: Clipart;

    @field()
    name: string;

    @field()
    order: number;

    // Set in DDB service for selector
    @field()
    selectorWidth: number;

    @field()
    selectorHeight: number;
}
