import {Collection} from '../../../core/models/Collection';
import {Color} from '../models/Color';

export class ColorCollection extends Collection<Color> {
    constructor(...items: Color[]) {
        super(...items);
        Object.setPrototypeOf(this, ColorCollection.prototype);
    }

    getDefault() {
        return this.find(v => v.default) || this[0]
    }

    getByName(name: string) {
        return this.find(v => v.name === name);
    }
}
