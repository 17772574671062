import {Collection} from '../../../core/models/Collection';
import {field} from '../../../core/models/fields/Field';
import {
    ForeignKeyURIField,
    TForeignKeyURI
} from '../../../core/models/fields/ForeignKeyURIField';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {ElementAbstract} from './abstract/ElementAbstract';
import {Clipart} from './Clipart';
import {CustomClipartRegion} from './CustomClipartRegion';
import {IClipart} from './IClipart';
import {TemplateClipart} from './TemplateClipart';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {APIResource} from '../../../core/models/APIResource';

export class CustomClipart extends ElementAbstract implements IClipart {
    static override uri: string = '/custom/api/v1/custom-clipart/:id/';
    static override objects: APIResource<CustomClipart>;

    @field(ForeignKeyField, {
        model: 'Clipart',
        readOnly: true,
    })
    clipart: Clipart;

    @field(ForeignKeyField, {
        model: 'TemplateClipart',
        readOnly: true
    })
    template_clipart: TemplateClipart;

    @field(ToManyField, {
        model: 'CustomClipartRegion',
        full: true
    })
    regions: Collection<CustomClipartRegion>;

    @field()
    search: string;

    override get root() {
        return this.template_clipart;
    }

    // Only allow get on custom, users should not change this (as of now)
    get use_as_mask() {
        if (this.template_clipart) {
            return (this.template_clipart as TemplateClipart).use_as_mask;
        }
        return false;
    }

    get label(): string {
        // Return template_clipart.name, if it's set
        const tc: TemplateClipart = this.template_clipart as TemplateClipart;
        if (tc && tc.name)
            return tc.name;

        // Return clipart.name, or Clipart Element if there's none
        return this.clipart.name ? this.clipart.name : 'Clipart Element';
    }

    get selectable(): boolean {
        if (this.template_clipart instanceof TemplateClipart && this.template_clipart.id) {
            return this.template_clipart.selectable;
        }
        return true;
    }

    get _allow_clipping_when_locked(): boolean {
        if (this.template_clipart instanceof TemplateClipart && this.template_clipart.id) {
            return this.template_clipart.allow_clipping_when_locked;
        }
        return true;
    }

    get show_cut_contour() {
        return this.template_clipart ? (this.template_clipart as TemplateClipart).show_cut_contour : false;
    }

    // Can remove override
    get _can_remove() {
        return this.template_clipart ? (this.template_clipart as TemplateClipart).can_remove : true;
    }

    set _can_remove(v){
        return;
    }
}
