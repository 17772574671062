import {RegionType} from "./enums";
import {field} from "../../../core/models/fields/Field";
import {IClipartRegion} from "./IClipartRegion";
import {RegionAbstract} from "./abstract/RegionAbstract";
import {APIResource} from '../../../core/models/APIResource';

export class ClipartRegion extends RegionAbstract implements IClipartRegion  {
    static override uri:string = '/custom/api/v1/clipart-region/:id/';
    static override objects: APIResource<ClipartRegion>;

    @field()
    name:string;

    @field()
    selector:string;

    @field()
    type:RegionType;

    @field()
    cutpath: boolean;

    @field()
    use_material_colors: boolean;

    @field()
    blend_mode_selector: string;

    get selectorId(): RegExpMatchArray {
        const idRegex = /([^#,\s]+)/g;
        return (this.selector || '').match(idRegex);
    }

    get blendSelectorId(): RegExpMatchArray {
        const idRegex = /([^#,\s]+)/g;
        return (this.blend_mode_selector || '').match(idRegex);
    }
}
