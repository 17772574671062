export default class PricingTier {
    public quantity: number;
    public price: number;
    public unitPrice: number;

    constructor(quantity, price) {
        quantity = Number(quantity);
        price = Number(price);

        this.quantity = quantity;
        this.price = price * quantity;
        this.unitPrice = price;
    }

    get label() {
        return `q-${this.quantity}`
    }
}