import {Collection} from '../../../core/models/Collection';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {ConfiguredProductAbstract} from './abstract/ConfiguredProductAbstract';
import {ClipartProduct} from './ClipartProduct';
import {ConfiguredClipartProductRegion} from './ConfiguredClipartProductRegion';
import {IConfiguredProduct} from './IConfiguredProduct';
import {APIResource} from '../../../core/models/APIResource';
import {ConfiguredClipartOption} from './ConfiguredClipartOption';

export class ConfiguredClipartProduct extends ConfiguredProductAbstract implements IConfiguredProduct {
    public static override uri: string = '/custom/api/v1/configured-clipart-product/:id/';
    static override objects: APIResource<ConfiguredClipartProduct>;
    public static override resource_name: string = 'configured-clipart-product';
    public override productClass: string = 'clipart';

    @field(ForeignKeyField, {
        model: 'ClipartProduct',
        readOnly: true
    })
    clipart_product: ClipartProduct;

    @field(ToManyField, {
        model: 'ConfiguredClipartProductRegion'
    })
    regions: Collection<ConfiguredClipartProductRegion>;

    @field(ToManyField, {
        model: 'ConfiguredClipartOption'
    })
    override options: Collection<ConfiguredClipartOption>;

    override set product(product: ClipartProduct) {
        this.clipart_product = product;
    }

    override get product(): ClipartProduct {
        return this.clipart_product;
    }

    override validate() {
        super.validate();

        if (this.product && this.product.min_sq_inches && this.cleaned.width * this.cleaned.height < this.product.min_sq_inches)
            this._errors.add('size', 'Dimensions are too small. Please increase the size.');

        if (this.product && this.product.max_height && this.cleaned.height && this.cleaned.height > this.product.max_height) {
            this._errors.add('size', `The height can not exceed ${this.product.max_height} inches`);
        }

        if (this.product && this.product.max_width && this.cleaned.width && this.cleaned.width > this.product.max_width) {
            this._errors.add('size', `The width can not exceed ${this.product.max_width} inches`);
        }

        return this._errors;
    }

    override getNextURL() {
        return '/cart/'
    }
}
