import {field} from '../../../core/models/fields/Field';
import {Model} from '../../../core/models/Model';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {APIResource} from '../../../core/models/APIResource';

export class CustomClipMask extends Model {
    static override uri: string = '/custom/api/v1/custom-clip-mask/:id/';
    static override objects: APIResource<CustomClipMask>;

    @field(ForeignKeyField, {
        model: 'CustomUserFile'
    })
    element: any;

    @field(ForeignKeyField, {
        model: 'CustomClipart'
    })
    mask: any;

    // We cannot delete elements prior to the customer saving their product
    // Queue it to be deleted when the product is saved.
    toDelete: boolean;

    remove() {
        this.toDelete = true;
        this.trigger('remove', this);
    }

    get label() {
        return 'Clip Mask Group';
    }

    get selectable() {
        return true;
    }

    get can_remove() {
        return true;
    }

    get maintain_proportion() {
        return false;
    }
}