import {Model} from '../../../core/models/Model';
import {ElementAbstract} from './abstract/ElementAbstract';
import {IElement} from './IElement';
import {Field, field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {APIResource} from '../../../core/models/APIResource';
import {MarketplaceArtwork} from '../../marketplace/models/MarketplaceArtwork';

export class CustomMarketplace extends ElementAbstract implements IElement {
    static override uri: string = '/custom/api/v1/custom-marketplace/:id/';
    static override objects: APIResource<CustomMarketplace>;

    @field(ForeignKeyField, {
        model: 'MarketplaceArtwork',
        readOnly: true
    })
    artwork: MarketplaceArtwork;

    @field()
    removed_background: boolean;

    get raster() {
        if (this.removed_background && this.artwork.public_raster_remove_bg) {
            return this.artwork.public_raster_remove_bg;
        }

        return this.artwork.public_raster_url;
    }

    get selectable() {
        return true;
    }
    set selectable(v) {
        return;
    }

    get label() {
        return this.artwork.name
    }

    get marketplace() {
        return true;
    }
}
