import {Collection} from '../../../core/models/Collection';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {
    ForeignKeyURIField,
    TForeignKeyURI
} from '../../../core/models/fields/ForeignKeyURIField';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {ConfiguredProductAbstract} from './abstract/ConfiguredProductAbstract';
import {ConfiguredDragDropBuyProductClipart} from './ConfiguredDragDropBuyProductClipart';
import {DragDropBuyProduct} from './DragDropBuyProduct';
import {DragDropBuyProductSize} from './DragDropBuyProductSize';
import {Font} from './Font';
import {IConfiguredProduct} from './IConfiguredProduct';
import {APIResource} from '../../../core/models/APIResource';

export enum SizeOptions {
    SMALL,
    MEDIUM,
    LARGE
}

export enum TextLocationOptions {
    NONE,
    BOTTOM,
    TOP
}

export class ConfiguredDragDropBuyProduct extends ConfiguredProductAbstract implements IConfiguredProduct {
    static override uri: string = '/custom/api/v1/configured-ddb-product/:id/';
    static override objects: APIResource<ConfiguredDragDropBuyProduct>;
    public static override resource_name: string = 'configured-ddb-product';
    override productClass: string = 'ddb';

    @field(ForeignKeyField, {
        model: 'DragDropBuyProduct',
        readOnly: true
    })
    base: DragDropBuyProduct;

    @field(ForeignKeyURIField, {
        model: 'DragDropBuyProductSize',
        readOnly: true
    })
    size: TForeignKeyURI<DragDropBuyProductSize>;

    @field()
    text: string;

    @field()
    text_size: SizeOptions = SizeOptions.MEDIUM;

    @field()
    text_location: TextLocationOptions = TextLocationOptions.NONE;

    @field(ForeignKeyURIField, {
        model: 'Font',
        readOnly: true
    })
    font: TForeignKeyURI<Font>;

    @field(ToManyField, {
        model: 'ConfiguredDragDropBuyProductClipart'
    })
    clipart_elements: Collection<ConfiguredDragDropBuyProductClipart>;

    @field()
    source: string;

    @field()
    canvas_preview: string;

    override set product(product: DragDropBuyProduct) {
        this.base = product;
    }

    override get product(): DragDropBuyProduct {
        return this.base;
    }
}
