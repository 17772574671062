import {field} from '../../../core/models/fields/Field';
import {Model} from '../../../core/models/Model';
import {IProductSize} from './abstract/ProductSizeAbstract';
import {APIResource} from '../../../core/models/APIResource';

export class DragDropBuyProductSize extends Model implements IProductSize {
    public static override uri: string = '/custom/api/v1/configured-ddb-product-size/:id/';
    static override objects: APIResource<DragDropBuyProductSize>;

    @field()
    public value: number;

    @field()
    public name: string;
}
