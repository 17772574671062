import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {APIResource} from '../../../core/models/APIResource';

export class ClipartCategoryGraphic extends Model {
    static override uri: string = '/custom/api/v1/clipart-category-graphic/:id/';
    static override objects: APIResource<ClipartCategoryGraphic>;

    @field()
    name: string;

    @field()
    preview: string;

    @field()
    uses: number;

    @field()
    orders: number;

    @field()
    graphic: any;
}