import {field} from "../../../core/models/fields/Field";
import {Model} from "../../../core/models/Model";
import {APIResource} from '../../../core/models/APIResource';

export class TemplateCategory extends Model {
    static override uri:string = '/custom/api/v1/template-category/:id/';
    static override objects: APIResource<TemplateCategory>;

    @field()
    canonical_url:string;

    @field()
    date_updated:string;

    @field()
    description:string;

    @field()
    display:string;

    @field()
    meta_description:string;

    @field()
    meta_keywords:string;

    @field()
    name:string;

    @field()
    short_description:string;

    @field()
    slug:string;

    @field()
    url:string;

    get absoluteUrl() {
        if(this.url) {
            return this.url;
        } else {
            return '/custom/products/category/id/' + this.slug + '/';
        }
    }
}
