import {Collection} from '../../../core/models/Collection';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {ProductAbstract} from './abstract/ProductAbstract';
import {Clipart} from './Clipart';
import {DragDropBuyProductSize} from './DragDropBuyProductSize';
import {IProduct} from './IProduct';
import {APIResource} from '../../../core/models/APIResource';
import {TemplateOptionSet} from './TemplateOptionSet';

export enum ELayout {
    VERTICAL,
    HORIZONTAL
}

export enum EVerticalAlignment {
    TOP,
    CENTER,
    BOTTOM
}

export enum EHorizontalAlignment {
    LEFT,
    CENTER,
    RIGHT
}

export enum DDBToolType {
    FAMILY,
    GREEK
}

export class DragDropBuyProduct extends ProductAbstract implements IProduct {
    public static override uri: string = '/custom/api/v1/ddb-product/:id/';
    static override objects: APIResource<DragDropBuyProduct>;

    @field(ToManyField, {
        model: 'DragDropBuyProductSize'
    })
    sizes: Collection<DragDropBuyProductSize>;

    @field()
    layout: ELayout;

    @field()
    vertical_alignment: EVerticalAlignment;

    @field()
    horizontal_alignment: EHorizontalAlignment;

    @field()
    tool_type: DDBToolType;

    @field()
    image: string;

    @field()
    max_clipart: number;

    @field(ForeignKeyField, {
        model: 'Clipart'
    })
    background: Clipart;

    @field()
    background_x: number;

    @field()
    background_y: number;

    @field()
    clipart_same_height: boolean;

    // todo: add back in foreign key if needed
    @field()
    video: any;

    // Not used
    options: Collection<TemplateOptionSet>;
}
