import {Model, TModelDefinitionList} from '../../../core/models/Model';
import {BaseMarketplaceProduct} from './BaseMarketplaceProduct';
import {ConfiguredMarketplaceProduct} from './ConfiguredMarketplaceProduct';
import {MarketplaceArtist} from './MarketplaceArtist';
import {MarketplaceArtwork} from './MarketplaceArtwork';
import {MarketplaceCompany} from './MarketplaceCompany';
import {MarketplaceProduct} from './MarketplaceProduct';
import {MarketplaceProductImage} from './MarketplaceProductImage';
import {MarketplaceProductSize} from './MarketplaceProductSize';
import {MarketplaceWatermark} from './MarketplaceWatermark';
import {ConfiguredShutterstockProduct} from './ConfiguredShutterstockProduct';
import {ShutterstockProductOption} from './ShutterstockProductOption';
import {ConfiguredMarketplaceOption} from './ConfiguredMarketplaceOption';

export default function registerModels():  void {
    const models: TModelDefinitionList = {
            MarketplaceArtist: MarketplaceArtist,
            MarketplaceArtwork: MarketplaceArtwork,
            MarketplaceCompany: MarketplaceCompany,
            MarketplaceWatermark: MarketplaceWatermark,
            BaseMarketplaceProduct: BaseMarketplaceProduct,
            MarketplaceProduct: MarketplaceProduct,
            MarketplaceProductSize: MarketplaceProductSize,
            MarketplaceProductImage: MarketplaceProductImage,
            ConfiguredMarketplaceProduct: ConfiguredMarketplaceProduct,
            ConfiguredShutterstockProduct: ConfiguredShutterstockProduct,
            ShutterstockProductOption: ShutterstockProductOption,
            ConfiguredMarketplaceOption: ConfiguredMarketplaceOption
    };

    Model.registerModels(models);
}
