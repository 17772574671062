import {Field} from './Field';

export class RotationField extends Field {
    public override clean(): number {
        let data = this.value;
        if (typeof(data) === 'string')
            data = parseFloat(data);

        if (isFinite(data)) {
            if (data > 359)
                data = data % 360;

            if (data < 0)
                data = data % 360 + 360;

            data = parseFloat(data.toFixed(3));
        }

        return data;
    }
}
