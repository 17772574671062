import {field} from '../../../core/models/fields/Field';
import {ColorCollection} from '../collections/ColorCollection';
import {ProductTypeEnum} from './enums';
import {MaxSizeAbstract} from './abstract/MaxSizeAbstract';
import {APIResource} from '../../../core/models/APIResource';

export class Color extends MaxSizeAbstract {
    static override uri: string = '/custom/api/v1/color/:id/';
    static override objects: APIResource<Color>;
    static override collectionClass = ColorCollection;

    @field()
    code: string;

    @field()
    default: boolean;

    @field()
    material: string;

    @field()
    name: string;

    @field()
    short_name: string;

    @field()
    order: number;

    @field()
    type: ProductTypeEnum;

    @field()
    background_color: string;

    @field()
    blend_mode: string;

    @field()
    texture: string;

    @field()
    texture_thumbnail: string;

    get fillValue() {
        return this.code ? this.code.slice(0, 3) == 'rgb' ? this.code : '#' + this.code : null;
    }

    get background() {
        if (this.texture_thumbnail) {
            return `url('${this.texture_thumbnail}')`;
        }
        if (this.texture) {
            return `url('${this.texture}')`;
        }
        return this.fillValue;
    }
}
