import {field} from '../../../core/models/fields/Field';
import {IElement} from './IElement';
import {BarCodeTypeDisplayMap, CodeType, QRCode, QRCodeTypeDisplayMap} from './QRCode';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {APIResource} from '../../../core/models/APIResource';
import VariableDataObjectElementAbstract from './abstract/VariableDataObjectElementAbstract';
import {SymbolType} from './enums';


export class CustomQRCode extends VariableDataObjectElementAbstract implements IElement {
    static override uri:string = '/custom/api/v1/custom-qrcode/:id/';
    static override objects: APIResource<CustomQRCode>;

    @field(ForeignKeyField, {
        model: 'QRCode',
        readOnly: true
    })
    qrcode: QRCode;

    selectable: boolean = true;

    override defaultRow(){
        return this.qrcode.data_json;
    }

    override onSymbolChange(old_value: SymbolType, new_value: SymbolType) {
        super.onSymbolChange(old_value, new_value);

        if (new_value == SymbolType.CSV_LIST) {
            this.variable_data = [this.qrcode.data_json];
        }
    }

    get label() {
        if (this.qrcode) {
            if (this.qrcode.code_type == CodeType.QR_CODE) {
                return QRCodeTypeDisplayMap[this.qrcode.qrcode_type] || 'QR Code';
            }

            if (this.qrcode.code_type == CodeType.BAR_CODE) {
                return BarCodeTypeDisplayMap[this.qrcode.barcode_type] ? `${BarCodeTypeDisplayMap[this.qrcode.barcode_type]} Bar Code - ${this.qrcode.data_json['value']}` : 'Bar Code';
            }
        }

        return 'Custom QR Code';
    }

    public override copy(from: any) {
        super.copy(from);
        this.qrcode = from.qrcode;
    }


    get text() {
        return this.qrcode.data
    }
    set text(v) {
        this.qrcode.data = v;
    }

    get _offset_width() {
        return 0
    }
    set _offset_width(v) {
        return;
    }
}
