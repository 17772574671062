import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {MarketplaceCompany} from './MarketplaceCompany';
import {APIResource} from '../../../core/models/APIResource';

export class MarketplaceArtist extends Model {
    public static override uri: string = '/marketplace/api/v1/artist/:id/';
    static override objects: APIResource<MarketplaceArtist>;

    @field(ForeignKeyURIField, {
        model: 'MarketplaceCompany'
    })
    public company: TForeignKeyURI<MarketplaceCompany>;

    @field()
    public name: string;

    @field()
    public slug: string;
}
