import {field} from "../../../core/models/fields/Field";
import {ToManyField} from "../../../core/models/fields/ToManyField";
import {Model} from "../../../core/models/Model";
import {Collection} from '../../../core/models/Collection';
import {ToManyURIField, TToManyKeyURI} from '../../../core/models/fields/ToManyURIField';
import {Material} from './Material';
import {TemplateOptionTag} from './TemplateOptionTag';
import {TemplateOption} from './TemplateOption';
import {APIResource} from '../../../core/models/APIResource';
import {DisplayTypeEnum} from './enums';

export class TemplateOptionSet extends Model {
    static override uri:string = '/custom/api/v1/template-option-set/:id/';
    static override objects: APIResource<TemplateOptionSet>;

    @field()
    display_type: DisplayTypeEnum;
    
    @field()
    help_text: string;
    
    @field()
    name: string;

    @field()
    short_description: string;
    
    @field(ToManyField, {
        model:'TemplateOption'
    })
    options: Collection<TemplateOption>;

    @field(ToManyURIField, {
        model: 'Material'
    })
    excluded_materials: TToManyKeyURI<Material>;

    @field(ToManyField, {
        model: 'TemplateOptionTag'
    })
    tags: Collection<TemplateOptionTag>;

    @field()
    minimum_width: number;

    @field()
    minimum_height: number;

    @field()
    maximum_width: number;

    @field()
    maximum_height: number;

    @field()
    quantity_breaks: string;

    @field()
    small_option: boolean;

    @field()
    required: boolean;

    public getTaggedOption(slug) {
        for (const option of this.options) {
            if (option.hasTag(slug)) {
                return option;
            }
        }

        return null;
    }

    public optionEligible(width, height) {
        if (this.minimum_height && height < this.minimum_height) {
            return false;
        }
        if (this.minimum_width && width < this.minimum_width) {
            return false;
        }
        if (this.maximum_height && height > this.maximum_height) {
            return false;
        }
        if (this.maximum_width && width > this.maximum_width) {
            return false;
        }

        return true;
    }

    public quantityBreaks(): number[] {
        if (!this.quantity_breaks) {
            return [];
        }

        return this.quantity_breaks.split(',').map(v => Number(v));
    }

    public quantityBreakIndex(quantity: number): number {
        let breaks = this.quantityBreaks();

        if (breaks.length == 0) {
            return null;
        }

        let index = 0;
        for (let i = 0; i < breaks.length; i++) {
            let value = breaks[i];

            if (value <= quantity) {
                index = i+1;
            }
        }

        return index;
    }
}
