import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {Model} from '../../../core/models/Model';
import {ProcessedFile} from './ProcessedFile';
import {ProofRequest} from './ProofRequest';
import {APIResource} from '../../../core/models/APIResource';

export class ProofRequestOption extends Model {
    public static override uri: string = '/processing/api/user/proof-request-option/:id/';
    static override objects: APIResource<ProofRequestOption>;

    @field()
    selected: boolean;

    @field()
    option_number: number;

    @field(ForeignKeyURIField, {
        model: 'ProofRequest'
    })
    proof_request: ForeignKeyURIField<ProofRequest>;

    @field(ForeignKeyField, {
        model: 'ProcessedFile'
    })
    processed_file: TForeignKeyURI<ProcessedFile>;

    @field()
    additional_info: string;
}
