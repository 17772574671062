import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {FloatField} from '../../../core/models/fields/FloatField';
import {APIResource} from '../../../core/models/APIResource';

export class PackageSize extends Model {
    static override uri: string = '/packaging/api/v1/package-size/:id/';
    static override objects: APIResource<PackageSize>;

    @field()
    name: string;

    @field(FloatField)
    package_width: number;

    @field(FloatField)
    package_height: number;

    @field(FloatField)
    max_product_width: number;

    @field(FloatField)
    max_product_height: number;

    @field(FloatField)
    min_product_width: number;

    @field(FloatField)
    min_product_height: number;
}