import {field, link} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {Model} from '../../../core/models/Model';
import {Order} from '../../order/models/Order';
import {OrderedItem} from '../../order/models/OrderedItem';
import {ProcessedFile} from './ProcessedFile';
import {APIResource} from '../../../core/models/APIResource';
import APIResponse from '../../../core/models/APIResponse';
import {ProofRequestOption} from './ProofRequestOption';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {Collection} from '../../../core/models/Collection';

export enum EProofRequestStatus {
    SENT = 0,
    APPROVED = 1,
    CHANGES_REQUESTED = 2,
    DONE = 3,
    EDITING = 100
}

export class ProofRequest extends Model {
    public static override uri: string = '/processing/api/user/proof-request/:id/';
    static override objects: APIResource<ProofRequest>;

    @field()
    version: number;

    @field(ForeignKeyURIField, {
        model: 'Order'
    })
    order: TForeignKeyURI<Order>;

    @field()
    order_id: number;

    @field(ForeignKeyURIField, {
        model: 'OrderedItem'
    })
    ordered_item: TForeignKeyURI<OrderedItem>;

    @field()
    ordered_item_line_item_number: number;

    @field(ForeignKeyField, {
        model: 'ProcessedFile'
    })
    processed_file: TForeignKeyURI<ProcessedFile>;

    @field()
    status: EProofRequestStatus;

    @field()
    additional_info: string;

    @field()
    changes_requested: string;

    @field()
    date_created: string;

    @field()
    date_customer_response: string;

    @field()
    differences: any[];

    @field(ToManyField, {
        model: 'ProofRequestOption'
    })
    options: Collection<ProofRequestOption>;

    @field()
    product_name: string;

    @field()
    quantity: number;

    @field()
    currency: string;

    @field()
    total_price: string;

    @field()
    unit_price: string;

    @field()
    size: string;

    public get statusDate(): string {
        if (this.status === EProofRequestStatus.SENT)
            return this.date_created;

        return this.date_customer_response;
    }

    public get statusDisplay(): string {
        switch (this.status) {
            case EProofRequestStatus.CHANGES_REQUESTED:
                return 'Changes Requested';
            case EProofRequestStatus.SENT:
                return 'Needs To Be Reviewed';
            case EProofRequestStatus.APPROVED:
                return 'Approved';
            case EProofRequestStatus.DONE:
                return 'Approved: In Production';
            case EProofRequestStatus.EDITING:
                return 'Preparing';
        }
    }

    public get statusCSSClass(): string {
        switch (this.status) {
            case EProofRequestStatus.CHANGES_REQUESTED:
                return 'proof-changes-requested';
            case EProofRequestStatus.SENT:
                return 'proof-sent';
            case EProofRequestStatus.APPROVED:
                return 'proof-approved';
            case EProofRequestStatus.DONE:
                return 'proof-done';
            case EProofRequestStatus.EDITING:
                return 'editing';
        }
    }
}
