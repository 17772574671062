import {APIResource} from '../../../core/models/APIResource';
import AbstractProductOption from '../../svgcustom/models/AbstractProductOption';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {ConfiguredMarketplaceProduct} from './ConfiguredMarketplaceProduct';

export class ConfiguredMarketplaceOption extends AbstractProductOption {
    public static override uri: string = '/marketplace/api/v1/marketplace-product-option/:id/';
    static override objects: APIResource<ConfiguredMarketplaceOption>;

    @field(ForeignKeyURIField, {
        model: 'ConfiguredMarketplaceProduct',
        readOnly: true
    })
    configured: TForeignKeyURI<ConfiguredMarketplaceProduct>;

    get custom() {
        return this.configured;
    }
    set custom(v) {
        this.configured = v;
    }
}