import {
    IProductSize,
    ProductSizeAbstract,
    TemplateSizeDisplayEnum
} from '../../svgcustom/models/abstract/ProductSizeAbstract';
import {Collection} from '../../../core/models/Collection';
import {APIResource} from '../../../core/models/APIResource';

export class TemplateSizeCollection extends Collection<IProductSize> {
    constructor(...items: IProductSize[]) {
        super(...items);
        Object.setPrototypeOf(this, TemplateSizeCollection.prototype);
    }

    getDefault() {
        const self: any = this;
        for (const i of self) {
            if (i.default)
                return i;
        }
    }

    setLabelDisplayOption(displayOption: TemplateSizeDisplayEnum) {
        const self: any = this;
        for (const i of self) {
            i.setLabelDisplayOption(displayOption);
        }
    }
}

export class MarketplaceProductSize extends ProductSizeAbstract {
    public static override uri: string = '/marketplace/api/v1/product-size/:id/';
    static override objects: APIResource<MarketplaceProductSize>;
    public static override collectionClass: any = TemplateSizeCollection;
}
