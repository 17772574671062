import {field} from "../../../core/models/fields/Field";
import {Model} from "../../../core/models/Model";
import {APIResource} from '../../../core/models/APIResource';

export class TextPath extends Model {
    static override uri:string = '/custom/api/v1/text-path/:id/';
    static override objects: APIResource<TextPath>;

    @field()
    display:string;

    @field()
    name:string;

    @field()
    path_data:string;
}
