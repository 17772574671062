import {Model, TModelDefinitionList} from '../../../core/models/Model';
import {Category} from './Category';
import {CategoryProduct} from './CategoryProduct';
import {CategoryChild} from './CategoryChild';
import {UsesLink} from './UsesLink';

export default function registerModels(): void {
    const models: TModelDefinitionList = {
        Category: Category,
        CategoryProduct: CategoryProduct,
        CategoryChild: CategoryChild,
        UsesLink: UsesLink
    };

    Model.registerModels(models);
}
