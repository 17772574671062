import {field} from "../../../core/models/fields/Field";
import {Model} from "../../../core/models/Model";
import {BackgroundImage} from "./BackgroundImage";
import {ToManyURIField} from "../../../core/models/fields/ToManyURIField";
import {Template} from "./Template";
import {Collection} from "../../../core/models/Collection";
import {APIResource} from '../../../core/models/APIResource';

export class ClipartProductCustomizationGroup extends Model {
    static override uri:string = '/custom/api/v1/clipart-product-customization-group/:id/';
    static override objects: APIResource<ClipartProductCustomizationGroup>;

    @field()
    name: string;

    @field(ToManyURIField, {
        model: 'Template',
    })
    customize_templates: Collection<Template>;
}
