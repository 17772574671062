import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {Custom} from './Custom';
import {Template} from './Template';
import {APIResource} from '../../../core/models/APIResource';

export default class CustomAutosave extends Model {
    static override uri: string = '/custom/api/v1/custom-autosave/:id/';
    static override objects: APIResource<CustomAutosave>;

    @field()
    name: string;

    @field()
    created: string;

    @field()
    updated: string;

    @field()
    preview: string;

    @field()
    data: string;

    @field(ForeignKeyURIField, {
        model: 'Custom'
    })
    custom: TForeignKeyURI<Custom>;

    @field(ForeignKeyURIField, {
        model: 'Template'
    })
    template: TForeignKeyURI<Template>;
}