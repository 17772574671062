import {ForeignKeyField} from "../../../core/models/fields/ForeignKeyField";
import {field} from "../../../core/models/fields/Field";
import {Model} from "../../../core/models/Model";
import {ClipartProduct} from "./ClipartProduct";
import {BackgroundImage} from "./BackgroundImage";
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {APIResource} from '../../../core/models/APIResource';

export class ClipartProductBackground extends Model {
    static override uri:string = '/custom/api/v1/clipart-product-background/:id/';
    static override objects: APIResource<ClipartProductBackground>;

    @field(ForeignKeyURIField, {
        model: 'ClipartProduct'
    })
    product: TForeignKeyURI<ClipartProduct>;

    @field(ForeignKeyField, {
        model: 'BackgroundImage'
    })
    image: BackgroundImage;

    @field()
    order: number;
}
