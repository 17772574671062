import {field} from "../../../core/models/fields/Field";
import {IClipartRegion} from "./IClipartRegion";
import {ClipartRegionAbstract} from "./abstract/ClipartRegionAbstract";
import {APIResource} from '../../../core/models/APIResource';

export class TemplateClipartRegion extends ClipartRegionAbstract implements IClipartRegion {
    static override uri:string = '/custom/api/v1/template-clipart-region/:id/';
    static override objects: APIResource<TemplateClipartRegion>;

    @field()
    template_clipart: string;

    @field()
    use_material_colors: boolean;
}
