import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {SpecialOffer} from './SpecialOffer';
import {APIResource} from '../../../core/models/APIResource';

export class ConfiguredSpecialOffer extends Model {
    static override uri: string = '/custom/api/v1/configured-special-offer/:id/';
    static override objects: APIResource<ConfiguredSpecialOffer>;

    @field(ForeignKeyField, {
        model: 'SpecialOffer',
        readOnly: true
    })
    special_offer: SpecialOffer;
}