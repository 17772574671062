import {ProductAbstract} from '../../svgcustom/models/abstract/ProductAbstract';
import {field} from '../../../core/models/fields/Field';
import {ToManyURIField, TToManyKeyURI} from '../../../core/models/fields/ToManyURIField';
import {BackgroundImage} from '../../svgcustom/models/BackgroundImage';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {Collection} from '../../../core/models/Collection';
import {StockProductSize} from '../../svgcustom/models/StockProductSize';
import {APIResource} from '../../../core/models/APIResource';
import {TemplateOptionSet} from '../../svgcustom/models/TemplateOptionSet';

export class BaseMarketplaceProduct extends ProductAbstract {
    public static override uri: string = '/marketplace/api/v1/base-product/:id/';
    static override objects: APIResource<BaseMarketplaceProduct>;

    @field()
    public create_cut_contour: boolean;

    @field(ToManyURIField, {
        model: 'BackgroundImage'
    })
    public backgrounds: TToManyKeyURI<BackgroundImage>;

    @field(ToManyField, {
        model: 'StockProductSize'
    })
    sizes: Collection<StockProductSize>;

    @field(ToManyField, {
        model: 'TemplateOptionSet'
    })
    options: Collection<TemplateOptionSet>;

    get allow_custom_size() {
        return true;
    }

    get hasOptions() {
        return this.options.length > 0;
    }
}
