import {field} from "../../../core/models/fields/Field";
import {Model} from "../../../core/models/Model";
import {APIResource} from '../../../core/models/APIResource';

export class TemplateOptionTag extends Model {
    static override uri:string = '/custom/api/v1/template-option-tag/:id/';
    static override objects: APIResource<TemplateOptionTag>;

    @field()
    name: string;

    @field()
    slug: string;

    @field()
    description: string;
}
