import {field} from '../../../../core/models/fields/Field';
import {
    ForeignKeyURIField,
    TForeignKeyURI
} from '../../../../core/models/fields/ForeignKeyURIField';
import {ClipartRegion} from '../ClipartRegion';
import {Color} from '../Color';
import {RegionType} from '../enums';
import {IClipartRegion} from '../IClipartRegion';
import {RegionAbstract} from './RegionAbstract';
import {ForeignKeyField} from '../../../../core/models/fields/ForeignKeyField';

export class ClipartRegionAbstract extends RegionAbstract implements IClipartRegion {

    @field()
    fill: string;

    @field(ForeignKeyField, {
        model: 'ClipartRegion',
        readOnly: true
    })
    region: ClipartRegion;

    @field()
    cutpath: boolean;

    /**
     * regionColor getter/setter are used by the color-selector component
     */
    set regionColor(color) {
        // UX: See the stroke as soon as you select a color if the current
        // stroke width is 0
        if (!this.stroke_width)
            this.stroke_width = 5;
        this.color = color;
    }

    get regionColor(): Color {
        return this.color as Color;
    }

    removeStroke(): void {
        this.color = null;
        this.stroke_width = 0;
    }

    get hasStroke(): boolean {
        return this.getField('stroke_width').initial_value > 0;
    }

    get selectorId(): RegExpMatchArray {
        if (this.region instanceof ClipartRegion)
            return this.region.selectorId;

        return null;
    }

    get type(): RegionType {
        if (this.region instanceof ClipartRegion)
            return this.region.type;

        return null;
    }
}
