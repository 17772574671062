import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {Model} from '../../../core/models/Model';
import {APIResource} from '../../../core/models/APIResource';

export class ProcessedFileFolder extends Model {
    public static override uri: string = '/processing/api/user/processed-file-folder/:id/';
    static override objects: APIResource<ProcessedFileFolder>;

    @field()
    public date_created: string;

    @field(ForeignKeyField, {
        model:'ProcessedFileFolder'
    })
    public parent_folder: ProcessedFileFolder;

    @field()
    public name: string;

    @field()
    public favorite: boolean;

    @field()
    public hidden: boolean;
}
