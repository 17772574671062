import {IClipartRegion} from "./IClipartRegion";
import {ClipartRegionAbstract} from "./abstract/ClipartRegionAbstract";
import {field} from '../../../core/models/fields/Field';
import {APIResource} from '../../../core/models/APIResource';

export class CustomClipartRegion extends ClipartRegionAbstract implements IClipartRegion {
    static override uri:string = '/custom/api/v1/custom-clipart-region/:id/';
    static override objects: APIResource<CustomClipartRegion>;

    @field()
    use_material_colors: boolean;
}
